import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { AddLinkIcon } from "../../materialUi/icons";
import {
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Box,
  Checkbox
} from "../../materialUi/components";
import {
  selectIsActivePageHome,
  selectActivePage,
  selectHomePage
} from "../../selectors";

import AddExternalLink from "../modal/AddExternalLinkDialog";

const PageContentForm = ({
  content,
  handleTextChange,
  handleAuthorizationChange,
  initialFolder,
  folders,
  newFolder,
  selectNewPageFolder,
  setNewPageFolder,
  handleSaveExternalLinks,
  handleisGamingURL
}) => {
  const ADDLINKMODAL = "ADDLINKMODAL";
  const [activePageFolderId, setActivePageFolderId] = useState(null);

  const currentFolder = newFolder || initialFolder;
  const isHomePage = useSelector(selectIsActivePageHome);
  const homePage = useSelector(selectHomePage) || {};
  const activePage = useSelector(selectActivePage);
  const urlInputDisabled = isHomePage || (activePage || {}).scheduledPage;
  const [showModal, setShowModal] = useState({
    [ADDLINKMODAL]: false
  });
  const [externalLinks, setExternalLinks] = useState([]);

  const handleModal = (modalName, value) => {
    setShowModal({ [modalName]: value });
  };

  const getSelectBoxOptions = (folders = []) => {
    let selectBoxOptions = [];
    folders.forEach((folder = {}) => {
      if (isHomePage) {
        folder.id === homePage.folderId &&
          selectBoxOptions.push(
            <MenuItem key={folder.id} value={folder.id}>
              {`${folder.title}${folder.scheduled ? " - Scheduler" : ""}`}
            </MenuItem>
          );
      } else {
        folder.id !== homePage.folderId &&
          selectBoxOptions.push(
            <MenuItem key={folder.id} value={folder.id}>
              {`${folder.title}${folder.scheduled ? " - Scheduler" : ""}`}
            </MenuItem>
          );
      }
    });
    return selectBoxOptions;
  };

  const updateExternalLinks = (externalLinks = []) => {
    const updatedExternalLinks = cloneDeep(externalLinks);
    setExternalLinks(
      updatedExternalLinks.map(link => {
        link.URL = link.URL.split("?")[0];
        return link;
      })
    );
  };

  if (activePage && activePage.folderId != activePageFolderId) {
    setActivePageFolderId(activePage.folderId);
  }

  useEffect(() => {
    if (activePageFolderId && initialFolder.id) {
      selectNewPageFolder(initialFolder);
    }
  }, [activePageFolderId]);

  useEffect(() => {
    setNewPageFolder(null);
  }, [activePage]);

  return (
    <Box sx={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
      {showModal[ADDLINKMODAL] && (
        <AddExternalLink
          handleSave={data => {
            handleSaveExternalLinks("externalLinks", data.externalLinks || []);
            handleModal(ADDLINKMODAL, false);
          }}
          data={{ externalLinks }}
          handleClose={() => {
            handleModal(ADDLINKMODAL, false);
          }}
          open={true}
          title={"ADD External Links"}
          dialogContext={"Please Add/Update External Links"}
        />
      )}
      <TextField
        autoFocus
        id="title"
        name="title"
        label="Title"
        type="text"
        value={get(content, "title", "")}
        onChange={handleTextChange}
        style={{ width: "28%" }}
        size="small"
        InputLabelProps={{ shrink: true }}
        placeholder="Enter Page Name"
        inputProps={{ maxLength: 30 }}
      />
      <TextField
        autoFocus
        id="url"
        name="url"
        label="URL"
        type="text"
        value={get(content, "url", "")}
        onChange={handleTextChange}
        style={{ width: "29%", marginLeft: "20px", marginRight: "20px" }}
        size="small"
        InputLabelProps={{ shrink: true }}
        placeholder="Enter Page URL (must start with '/')"
        disabled={urlInputDisabled}
      />
      <TextField
        select
        label="Select Folder"
        onChange={ev => {
          const index = folders.findIndex(
            folder => folder.id === ev.target.value
          );
          if (index > -1) {
            selectNewPageFolder(folders[index]);
          }
        }}
        placeholder="Please Select Folder"
        size="small"
        sx={{ width: "25%" }}
        value={currentFolder?.id || ""}
        InputLabelProps={{ shrink: true }}
        disabled={isHomePage}
      >
        {getSelectBoxOptions(folders)}
      </TextField>

      <Tooltip title="Add Page Links" placement="top" arrow>
        <IconButton
          onClick={() => {
            updateExternalLinks(content?.externalLinks || []);
            handleModal(ADDLINKMODAL, true);
          }}
          sx={{ marginLeft: "20px" }}
        >
          <AddLinkIcon />
        </IconButton>
      </Tooltip>
      {!urlInputDisabled && (
        <Tooltip title="Authorization" placement="top" arrow>
          <Checkbox
            color="primary"
            checked={get(content, "authorization", false)}
            name="authorization"
            onChange={handleAuthorizationChange}
            sx={{ float: "right" }}
          />
        </Tooltip>
      )}
      <Tooltip title="Gaming URL" placement="top" arrow>
        <Checkbox
          color="primary"
          checked={get(content, "isGamingURL", false)}
          name="isGamingURL"
          onChange={handleisGamingURL}
          sx={{ float: "right" }}
        />
      </Tooltip>
    </Box>
  );
};

export default PageContentForm;
