import React, { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import FlipMove from "react-flip-move";
import "react-input-range/lib/css/index.css";
import {
  bannerBackgroundOptions,
  bannerDesktopBackgroundOptions,
  deviceTypes,
  UNSAVED_CHANGES_WARNING_MESSAGE,
  bannerGradientTypes,
  backgroundRepeatOptions,
  backgroundSizeOptions,
  backgroundPositionOptions,
  IMAGE_SIZE_AND_TYPE_TOOLTIP,
  imageFormatArr,
} from "../../../constants";
import {
  TextField, Box, MenuItem, Typography, Slider
} from '../../../materialUi/components';
import { BannerRow } from "../BannerRow";
import SpacingControls from "../SpacingControlsMUI";
import { UploadImageModal } from "../ItemModal/UploadImageModal";
import { ColorPicker } from "../../ColorPicker";
import CustomModal from "../../../containers/modal/ConfirmModal";
import ImageSelectionModal from "../../../containers/modal/imageSelectionModalMUI";
import {
  generateImages,
  toggleListItem,
  deleteListItem,
  updateHomepageListData,
  updateBannerSectionDataStyles,
  putBannerDataStyles
} from "../../../actions/dynamicBanner.action";
import { setAccordionState, toastMsg } from "../../../actions/common.action";



export const BannerTabContent = ({
  id,
  listPosition,
  content,
  deviceType,
  isScheduler,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [displayColorPickerById, setDisplayColorPickerById] = useState({});
  const [imageSelectionModal, setImageSelectionModal] = useState(false);


  const rows = get(content, "rows", []);

  const defaultPath = useMemo(
    () =>
      isScheduler
        ? [listPosition, deviceType, "schedulers", schedulerIndex]
        : [listPosition, deviceType],
    [deviceType, isScheduler, listPosition, schedulerIndex]
  );

  const enabledDeviceLevelScheduler = !!get(content, "schedulers", []).find(
    scheduler => scheduler.enabled
  );

  const toggleRow = ({ rowId }) => {
    dispatch(
      toggleListItem({
        path: [...defaultPath, "rows", rowId],
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const deleteRow = ({ rowId }) => {
    dispatch(
      deleteListItem({
        path: [...defaultPath, "rows"],
        listIndex: rowId,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const updateRowData = ({ name, value, rowId }) => {
    dispatch(
      updateHomepageListData({
        path: [...defaultPath, "rows", rowId],
        name,
        value,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const generateRows = value => {
    const haveRowSomeData = rows.some(row => row.bannerType);

    if (haveRowSomeData && value < rows.length) {
      setIsModalVisible(true);
      setRowCount(value);
    } else {
      dispatch(
        generateImages({
          path: [...defaultPath, "rows"],
          numberOfRows: value,
          pageType,
          updateCatalogPageType,
          updateCatalogPageLocation
        })
      );
    }
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    dispatch(
      generateImages({
        path: [...defaultPath, "rows"],
        numberOfRows: rowCount,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const handleChangeBannerSectionDataStyles = (name, value) =>
    dispatch(
      updateBannerSectionDataStyles({
        path: defaultPath,
        name,
        value,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );

  const handleSpacingChange = value =>
    dispatch(
      putBannerDataStyles({
        value,
        path: defaultPath,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  const deleteImage = backgroundType => {
    handleChangeBannerSectionDataStyles(backgroundType, "");
  };

  const backgroundType = get(content, `styles.backgroundType`, null);

  const backgroundImage = get(content, `styles.backgroundImage`, null);

  const backgroundRepeat = get(content, `styles.backgroundRepeat`, null);
  const backgroundSize = get(content, `styles.backgroundSize`, null);
  const backgroundPosition = get(content, `styles.backgroundPosition`, 0);
  const gradientAngle = parseInt(get(content, `styles.gradientAngle`, 0) || 0);

  const primaryBackgroundColor = get(
    content,
    `styles.primaryBackgroundColor`,
    "#ffffff"
  );

  const primaryLocation = parseInt(get(content, `styles.primaryLocation`, 0) || 0);

  const secondaryBackgroundColor = get(
    content,
    `styles.secondaryBackgroundColor`,
    "#ffffff"
  );

  const secondaryLocation = parseInt(get(content, `styles.secondaryLocation`, 0) || 0);

  const gradientType = get(content, `styles.gradientType`, '');

  const showAngle = gradientType === bannerGradientTypes[0].value;

  const gradientStyles = {
    width: "100%",
    height: "140px",
    background: get(content, `styles.backgroundGradient`, "#ffffff"),
    border: "1px solid #555555"
  };

  const handlePickerClickById = (id) =>
    setDisplayColorPickerById(prevState => ({ ...prevState, [id]: !prevState[id] }));

  const getNoOfRowsOptions = () => {
    const options = Array.from(Array(10), (_, i) => {
      return { label: i + 1, value: i + 1 };
    });

    return options.map((option) =>
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  const getBgTypeOptions = () => {
    const options = deviceType === deviceTypes.desktop
      ? bannerDesktopBackgroundOptions
      : bannerBackgroundOptions

    return options.map((option) =>
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  const handeImageSelectionModal = () => {
    setImageSelectionModal(!imageSelectionModal);
  };

  const handleImageUploadSuccess = (
    keyName,
    cdnURL,
    brandId,
    imageLanguage,
    modalName,
    fileName
  ) => {
    handleChangeBannerSectionDataStyles(backgroundType, cdnURL);
    setImageSelectionModal(false);
  };

  return (
    <Fragment>
      {isModalVisible && (
        <CustomModal
          modalName="confirmation-modal"
          onHide={() => setIsModalVisible(false)}
          confirm={handleModalConfirm}
          message={UNSAVED_CHANGES_WARNING_MESSAGE}
        />
      )}
      <Box sx={{ color: "#333" }}>
        <Box sx={{ display: "flex", alignItems: "end", marginBottom: "20px" }}>
          <TextField
            select
            label="Select Number of Rows"
            onChange={(event) =>
              generateRows(get(event, "target.value", null))
            }
            placeholder="Please Select Number of Rows"
            size="small"
            sx={{ width: "30%" }}
            value={rows.length}
            name="rowCount"
            InputLabelProps={{ shrink: true }}
          >
            {getNoOfRowsOptions()}
          </TextField>
          <SpacingControls
            styles={get(content, "styles", {})}
            handleSpacingChange={handleSpacingChange}
            CommonStyle={{ display: "flex", width: "60%", marginLeft: "20px" }}
            paddingStyle={{ width: "70%" }}
            marginStyle={{ width: "30%", marginLeft: "20px" }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "end", marginBottom: "20px" }}>
          <TextField
            select
            label="Select Background Type"
            onChange={(event) =>
              handleChangeBannerSectionDataStyles(
                "backgroundType",
                get(event, "target.value", null)
              )
            }
            placeholder="Please Select Background Type"
            size="small"
            sx={{ width: "60%" }}
            value={backgroundType}
            name="backgroundType"
            InputLabelProps={{ shrink: true }}
          >
            {getBgTypeOptions()}
          </TextField>
          {backgroundType === "backgroundColor" &&
            <Box className="mui-countdown" style={{ marginLeft: "20px", width: "30%" }}>
              <span>Bg color</span>
              <span
                onClick={() =>
                  handleChangeBannerSectionDataStyles("backgroundColor", "")
                }
                className="countdown-delete-icon"
              />
              <Box sx={{ marginTop: "10px" }}>
                <ColorPicker
                  id="bg-color"
                  onClick={() => handlePickerClickById("bg-color")}
                  background={get(content, "styles.backgroundColor", "#ffffff")}
                  displayPicker={!!displayColorPickerById["bg-color"]}
                  onChange={changedColor =>
                    handleChangeBannerSectionDataStyles(
                      "backgroundColor",
                      changedColor.hex
                    )
                  }
                />
              </Box>
            </Box>}
        </Box>

        {backgroundType === "backgroundImage" && (
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
            {imageSelectionModal && (
              <ImageSelectionModal
                title="Upload Image"
                modalName={"imageSelectionModal"}
                keyName="modal"
                language={"english"}
                containerName={"dynamicBanner"}
                onHide={() => setImageSelectionModal(false)}
                onSuccess={handleImageUploadSuccess}
                isOpen={imageSelectionModal}
                handleClose={() => setImageSelectionModal(false)}
                imageFormatArr={imageFormatArr}
                disableCDNuploadOption={false}
              />
            )}
            <Box sx={{ width: "30%" }}>
              <UploadImageModal
                imageURL={backgroundImage || ""}
                language="english"
                handeImageSelectionModal={handeImageSelectionModal}
                deleteImage={() => deleteImage(backgroundType)}
                infoIconTitle={IMAGE_SIZE_AND_TYPE_TOOLTIP}
              />
            </Box>
            <Box sx={{ display: "flex", width: "60%", marginLeft: "20px" }}>
              <TextField
                select
                label="Select Background Size"
                onChange={(event) =>
                  handleChangeBannerSectionDataStyles(
                    "backgroundSize",
                    get(event, "target.value", null)
                  )
                }
                placeholder="Please Select Background Size"
                size="small"
                sx={{ width: "20%" }}
                value={backgroundSize}
                name="backgroundSize"
                InputLabelProps={{ shrink: true }}
              >
                {backgroundSizeOptions.map((option) =>
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </TextField>
              <TextField
                select
                label="Select Background Repeat"
                onChange={(event) =>
                  handleChangeBannerSectionDataStyles(
                    "backgroundRepeat",
                    get(event, "target.value", null)
                  )
                }
                placeholder="Please Select Background Repeat"
                size="small"
                sx={{ width: "20%", marginLeft: "20px" }}
                value={backgroundRepeat}
                name="backgroundRepeat"
                InputLabelProps={{ shrink: true }}
              >
                {backgroundRepeatOptions.map((option) =>
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </TextField>
              <TextField
                select
                label="Select Background Position"
                onChange={(event) =>
                  handleChangeBannerSectionDataStyles(
                    "backgroundPosition",
                    get(event, "target.value", null)
                  )
                }
                placeholder="Please Select Background Position"
                size="small"
                sx={{ width: "20%", marginLeft: "20px" }}
                value={backgroundPosition}
                name="backgroundPosition"
                InputLabelProps={{ shrink: true }}
              >
                {backgroundPositionOptions.map((option) =>
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </TextField>
            </Box>
          </Box>
        )}

        {backgroundType === "backgroundGradient" && (
          <Box sx={{ display: "flex", marginBottom: "20px", background: "#f9f9f9" }}>
            <Box sx={{ width: "20%", marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }}>
              <Box className="mui-countdown" style={{}}>
                <span>Primary Color</span>
                <span
                  onClick={() =>
                    handleChangeBannerSectionDataStyles("primaryBackgroundColor", "")
                  }
                  className="countdown-delete-icon"
                />
                <Box sx={{ marginTop: "10px" }}>
                  <ColorPicker
                    id="primary-color"
                    onClick={() => handlePickerClickById("primary-color")}
                    background={primaryBackgroundColor}
                    displayPicker={!!displayColorPickerById["primary-color"]}
                    onChange={changedColor =>
                      handleChangeBannerSectionDataStyles(
                        "primaryBackgroundColor",
                        changedColor.hex
                      )
                    }
                  />
                </Box>
              </Box>
              <Box className="mui-countdown" style={{ marginTop: "20px" }}>
                <span>Secondary Color</span>
                <span
                  onClick={() =>
                    handleChangeBannerSectionDataStyles("secondaryBackgroundColor", "")
                  }
                  className="countdown-delete-icon"
                />
                <Box sx={{ marginTop: "10px" }}>
                  <ColorPicker
                    id="secondary-color"
                    onClick={() => handlePickerClickById("secondary-color")}
                    background={secondaryBackgroundColor}
                    displayPicker={!!displayColorPickerById["secondary-color"]}
                    onChange={changedColor =>
                      handleChangeBannerSectionDataStyles(
                        "secondaryBackgroundColor",
                        changedColor.hex
                      )
                    }
                  />
                </Box>
              </Box>
              <TextField
                select
                label="Select Gradient type"
                onChange={(event) =>
                  handleChangeBannerSectionDataStyles(
                    "gradientType",
                    get(event, "target.value", "")
                  )
                }
                placeholder="Please Select Gradient type"
                size="small"
                sx={{ marginTop: "20px", width: "100%" }}
                value={gradientType}
                name="gradientType"
                InputLabelProps={{ shrink: true }}
              >
                {bannerGradientTypes.map((option) =>
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </TextField>
            </Box>
            <Box sx={{ width: "50%", marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }}>
              <Box sx={{ "display": "flex", "alignItems": "center" }}>
                <Typography sx={{ "width": "10%" }}>
                  Primary Location
                </Typography>
                <Slider
                  value={primaryLocation}
                  max={100}
                  min={0}
                  step={10}
                  onChange={(_, value) =>
                    handleChangeBannerSectionDataStyles("primaryLocation", value)
                  }
                  size="medium"
                  sx={{ "marginLeft": "10px", "marginRight": "10px", "width": "80%" }}
                />
                <TextField
                  type="Number"
                  name={"primaryLocation"}
                  value={primaryLocation || 0}
                  onChange={event => {
                    let value = event.target.value;
                    if (value > 100) {
                      value = 100;
                    } else if (value < 0) {
                      value = 0;
                    }
                    handleChangeBannerSectionDataStyles("primaryLocation", value)
                  }
                  }
                  size="small"
                  sx={{ "width": "10%" }}
                // disabled
                />
              </Box>
              <Box sx={{ "display": "flex", "alignItems": "center", marginTop: "20px" }}>
                <Typography sx={{ "width": "10%" }}>
                  Secondary Location
                </Typography>
                <Slider
                  value={secondaryLocation}
                  max={100}
                  min={0}
                  step={10}
                  onChange={(_, value) =>
                    handleChangeBannerSectionDataStyles("secondaryLocation", value)
                  }
                  size="medium"
                  sx={{ "marginLeft": "10px", "marginRight": "10px", "width": "80%" }}
                />
                <TextField
                  type="Number"
                  name={"secondaryLocation"}
                  value={secondaryLocation || 0}
                  onChange={event => {
                    let value = event.target.value;
                    if (value > 100) {
                      value = 100;
                    } else if (value < 0) {
                      value = 0;
                    }
                    handleChangeBannerSectionDataStyles("secondaryLocation", value)
                  }
                  }
                  size="small"
                  sx={{ "width": "10%" }}
                />
              </Box>
              {showAngle && (
                <Box sx={{ "display": "flex", "alignItems": "center", marginTop: "20px" }}>
                  <Typography sx={{ "width": "10%" }}>
                    Angle
                  </Typography>
                  <Slider
                    value={gradientAngle}
                    max={360}
                    min={0}
                    step={36}
                    onChange={(_, value) =>
                      handleChangeBannerSectionDataStyles("gradientAngle", value)
                    }
                    size="medium"
                    sx={{ "marginLeft": "10px", "marginRight": "10px", "width": "80%" }}
                  />
                  <TextField
                    type="Number"
                    name={"gradientAngle"}
                    value={gradientAngle || 0}
                    onChange={event => {
                      let value = event.target.value;
                      if (value > 360) {
                        value = 360;
                      } else if (value < 0) {
                        value = 0;
                      }
                      handleChangeBannerSectionDataStyles("gradientAngle", value)
                    }
                    }
                    size="small"
                    sx={{ "width": "10%" }}
                  // disabled
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ width: "25%", marginLeft: "20px", marginRight: "20px", marginTop: "20px", marginBottom: "20px" }}>
              <Typography sx={{ "width": "10%" }}>
                Preview
              </Typography>
              <Box sx={{ marginTop: "10px", marginRight: "10px", ...gradientStyles }}></Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box className="rows-wrapper">
        <FlipMove typeName={null}>
          {rows.map((item, idx) => (
            <BannerRow
              rowIndex={idx}
              key={item.id}
              toogleRow={() => toggleRow({ rowId: idx })}
              deleteRow={() => deleteRow({ rowId: idx })}
              updateRowData={(name, value) =>
                updateRowData({ name, value, rowId: idx })
              }
              rowData={item}
              listPosition={listPosition}
              deviceType={deviceType}
              rows={rows}
              pageType={pageType}
              updateCatalogPageType={updateCatalogPageType}
              updateCatalogPageLocation={updateCatalogPageLocation}
              path={defaultPath}
              enabledDeviceLevelScheduler={enabledDeviceLevelScheduler}
              isScheduler={isScheduler}
            />
          ))}
        </FlipMove>
      </Box>
    </Fragment >
  );
};
