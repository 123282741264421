import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _, { cloneDeep } from "lodash";
import { DownloadIcon } from "../../materialUi/icons";
import moment from "moment";
import { CSVLink } from "react-csv";
import { snackbar } from "../../actions/common.action";
import { fetchAppReviewData } from "../../actions/appReview.action";

import {
  Button,
  TextField,
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Typography,
  TablePagination
} from "../../materialUi/components";
import { DateTimePicker } from "../../materialUi/datePicker";
import { convertDataToCSVFormt } from "../../util";

function UpdatePageContent({}) {
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [appReviews, setAppReviews] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleSubmit = async (pageNumber, rowsPerPage) => {
    if (moment(fromDate) > moment(toDate)) {
      return dispatch(
        snackbar({
          open: true,
          severity: "error",
          text: `The "From Date" should be less than or equal to the "To Date."`
        })
      );
    }
    if (moment(fromDate) > moment()) {
      return dispatch(
        snackbar({
          open: true,
          severity: "error",
          text: `"From Date" should be less than or equal to the "Current Date."`
        })
      );
    }
    const limit = rowsPerPage;
    const skip = pageNumber * rowsPerPage;
    const response = await dispatch(
      fetchAppReviewData(
        new Date(Date.parse(fromDate)),
        new Date(Date.parse(toDate)),
        limit,
        skip
      )
    );
    if (response.status === 200 && response.data?.data?.result) {
      setAppReviews(response.data.data.result.reviews || []);
      setTotalCount(response.data.data.result.totalCount || 0);
      setPageNumber(pageNumber);
      setRowsPerPage(rowsPerPage);
    }
  };

  const handleChangePage = (event, pageNumber) => {
    handleSubmit(pageNumber, rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    handleSubmit(0, parseInt(event.target.value, 10));
  };

  return (
    <Box sx={{ margin: "20px 20px 20px 20px" }}>
      <Box sx={{ marginTop: "5px", marginBottom: "5px" }}>
        <DateTimePicker
          label="From Date"
          value={moment(fromDate)}
          onChange={val => {
            setFromDate(val ? moment(val).format() : val);
          }}
          renderInput={params => <TextField {...params} />}
          disableFuture
          sx={{ minWidth: "30%", marginRight: "20px" }}
          slotProps={{ textField: { size: "small", error: false } }}
        />
        <DateTimePicker
          label="To Date"
          value={moment(toDate)}
          onChange={val => {
            setToDate(val ? moment(val).format() : val);
          }}
          renderInput={params => <TextField {...params} />}
          sx={{ minWidth: "30%", marginLeft: "20px", marginRight: "20px" }}
          maxDate={moment()}
          slotProps={{ textField: { size: "small", error: false } }}
        />

        <Button
          variant="contained"
          onClick={() => handleSubmit(0, rowsPerPage)}
          disabled={!fromDate || !toDate}
        >
          Submit
        </Button>
        <CSVLink
          data={convertDataToCSVFormt(
            [
              "Customer Name",
              "Customer Email",
              "Is Guest",
              "Platform",
              "Feedback"
            ],
            ["name", "email", "isGuest", "platform", "feedback"],
            cloneDeep(appReviews)
          )}
          filename={`${new Date()}-reviews.csv`}
        >
          <Tooltip title="Export to CSV" arrow placement="top">
            <IconButton disabled={false}>
              <DownloadIcon sx={{ color: "black" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </CSVLink>
      </Box>
      <Divider
        variant="fullWidth"
        sx={{ marginTop: "16px", marginBottom: "16px" }}
      />
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  key={"index"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"S.No"}
                </TableCell>
                <TableCell
                  key={"name"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Customer Name"}
                </TableCell>
                <TableCell
                  key={"email"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Customer Email"}
                </TableCell>
                <TableCell
                  key={"isGuest"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Is Guest"}
                </TableCell>
                <TableCell
                  key={"platform"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Platform"}
                </TableCell>
                <TableCell
                  key={"feedback"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Feedback"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appReviews.map((item, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  style={{ maxHeight: "15px" }}
                >
                  <TableCell key={"index"} align={"left"}>
                    {index + 1}
                  </TableCell>
                  <TableCell key={"name"} align={"left"}>
                    {item.name}
                  </TableCell>
                  <TableCell key={"email"} align={"left"}>
                    {item.email}
                  </TableCell>
                  <TableCell key={"isGuest"} align={"left"}>
                    {item.isGuest ? "true" : "false"}
                  </TableCell>
                  <TableCell key={"platform"} align={"left"}>
                    {item.platform}
                  </TableCell>
                  <TableCell key={"feedback"} align={"left"}>
                    {item.feedback || ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!appReviews.length ? (
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px"
            }}
          >
            No Records Found
          </Typography>
        ) : (
          <TablePagination
            component="div"
            count={totalCount}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                marginTop: "1em",
                marginBottom: "1em"
              }
            }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        )}
      </Paper>
    </Box>
  );
}

export default UpdatePageContent;
