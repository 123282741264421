import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import RightPanel from "./rightPanel";
import { changeTab } from "../../actions/common.action";
import Subheader from "../../component/subheader";
import { NAVIGATION, NAVIGATION_MENUS_SLUGS } from "../../constants";

class AppReview extends Component {
  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.APP_REVIEW);
  }

  render() {
    const { user } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.APP_REVIEW]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    return (
      <div
        className="row"
        style={{
          backgroundColor: "rgb(242, 245, 249)",
          marginLeft: 0,
          marginRight: 0,
          paddingTop: "10px",
          minHeight: "540px"
        }}
      >
        <div className="component-header">
          <div
            className="col-sm-12"
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              minHeight: "450px"
            }}
          >
            <Subheader showCountry={false} breadcrumArr={[]} />
            <RightPanel accessRights={accessRights} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user
});

const mapDispatchToProps = dispatch => ({
  changeTab: bindActionCreators(changeTab, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppReview);
