import React, { Fragment, useMemo, useState } from "react";
import get from "lodash/get";

import { BannerImageModal } from "./BannerImageModal";
import { CountdownModal } from "./CountdownModal";

import {
  dynamicBannerItemsTypes,
  bannerTypeValueConstants
} from "../../../constants";
import { VideoModal } from "./VideoModal";
import { PreviewVideoModal } from "./PreviewVideoModal";

import play from "../../../images/play.png";

export const ItemModal = ({
  item,
  imgCls,
  itemListIndex,
  bannerType,
  deviceType,
  rowIndex,
  schedulerIndex,
  handleDeleteImage,
  modalOpen,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  path
}) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const [isPreviewVideoModalOpen, setIsPreviewVideoModalOpen] = useState(false);

  const defaultPath = useMemo(() => [...path, "imageList", itemListIndex], [
    itemListIndex,
    path
  ]);

  const handleModalVisible = () => {
    setIsModalOpen(isModalOpen => {
      if (
        isModalOpen &&
        bannerType !== bannerTypeValueConstants.CAROUSEL &&
        !item.validated
      ) {
        handleDeleteImage();
      }

      return !isModalOpen;
    });
  };

  const handleSetModalOpen = open => setIsModalOpen(open);
  const handleSetPreviewVideoModalOpen = () =>
    setIsPreviewVideoModalOpen(prev => !prev);

  const renderImage = () => {
    const width = get(item, `english.width`, "");
    const ratio = get(item, `english.ratio`, "");
    let height = get(item, `english.height`, "");
    if (!height && ratio && width) {
      height = (ratio / 100) * width;
    }
    return (
      <div className="image-view form-group preview hover-icon">
        <BannerImageModal
          image={item}
          isOpen={isModalOpen}
          handleClose={() => {
            if (!(item?.english?.imageURL && item?.arabic?.imageURL)) {
              handleDeleteImage();
            }
            handleSetModalOpen(false);
          }}
          handleSetModalOpen={handleSetModalOpen}
          imgCls={imgCls}
          itemListIndex={itemListIndex}
          deviceType={deviceType}
          bannerType={bannerType}
          rowIndex={rowIndex}
          schedulerIndex={schedulerIndex}
          pageType={pageType}
          updateCatalogPageType={updateCatalogPageType}
          updateCatalogPageLocation={updateCatalogPageLocation}
          path={defaultPath}
        />
        {get(item, `english.imageURL`, "") ? (
          <Fragment>
            <span>
              <span className="img-overlay" />
              <span className="icon-close" onClick={handleDeleteImage} />
            </span>
            <span className="hover-camera-icon" onClick={handleModalVisible} />
            <span className="label-height-width">
              {`w:${(width && Math.ceil(width))||""}px  x  h:${(height && Math.ceil(height))||""}px`}
            </span>
            <span>
              <img
                className="img-dimension"
                src={get(item, `english.imageURL`, "")}
              />
            </span>
          </Fragment>
        ) : (
          <Fragment>
            <span className="icon-close" onClick={handleDeleteImage} />
            <span className="camera-icon" onClick={handleModalVisible} />
          </Fragment>
        )}
      </div>
    );
  };

  const renderCountdown = () => (
    <div className="image-view form-group preview hover-icon">
      <CountdownModal
        item={item}
        isOpen={isModalOpen}
        handleClose={() => {
          if (!(item?.date)) {
            handleDeleteImage();
          }
          handleSetModalOpen(false);
        }}
        handleSetModalOpen={handleSetModalOpen}
        itemListIndex={itemListIndex}
        deviceType={deviceType}
        rowIndex={rowIndex}
        schedulerIndex={schedulerIndex}
        pageType={pageType}
        updateCatalogPageType={updateCatalogPageType}
        updateCatalogPageLocation={updateCatalogPageLocation}
        path={defaultPath}
        bannerType={bannerType}
      />
      <Fragment>
        <span className="icon-close" onClick={handleDeleteImage} />
        <span className="camera-icon" onClick={handleModalVisible}></span>
        <span className="countdown-data">
          {item.date && new Date(item.date).toLocaleString()}
        </span>
      </Fragment>
    </div>
  );

  const renderVideo = () => (
    <div className="image-view form-group preview hover-icon">
      <VideoModal
        item={item}
        isOpen={isModalOpen}
        handleClose={() => {
          if (!(item?.english?.videoUrl && item?.arabic?.videoUrl)) {
            handleDeleteImage();
          }
          handleSetModalOpen(false);
        }}
        handleSetModalOpen={handleSetModalOpen}
        deviceType={deviceType}
        pageType={pageType}
        updateCatalogPageType={updateCatalogPageType}
        updateCatalogPageLocation={updateCatalogPageLocation}
        path={defaultPath}
      />
      <PreviewVideoModal
        videoURL={get(item, `english.videoUrl`, "")}
        handleClose={handleSetPreviewVideoModalOpen}
        dialogContext={"Please Preview the below video"}
        open={isPreviewVideoModalOpen}
        title={"Video Preview"}
      />
      {get(item, `english.videoUrl`, "") ? (
        <Fragment>
          <span>
            <span className="img-overlay" />
            <span className="icon-close" onClick={handleDeleteImage} />
            <span
              className="icon-play"
              onClick={handleSetPreviewVideoModalOpen}
            />
          </span>
          <span className="hover-camera-icon" onClick={handleModalVisible} />
          <span>
            <p className="img-dimension">
              <img
                className="video-icon"
                //src={get(item, `english.videoUrl`, '')}
                src={play}
              />
            </p>
          </span>
        </Fragment>
      ) : (
        <Fragment>
          <span className="icon-close" onClick={handleDeleteImage} />
          <span className="camera-icon" onClick={handleModalVisible} />
        </Fragment>
      )}
    </div>
  );

  const handleRender = () => {
    switch (item.type) {
      case dynamicBannerItemsTypes.slide:
      case dynamicBannerItemsTypes.image: {
        return renderImage();
      }
      case dynamicBannerItemsTypes.countdown: {
        return renderCountdown();
      }
      case dynamicBannerItemsTypes.video: {
        return renderVideo();
      }

      default: {
        return renderImage();
      }
    }
  };

  return handleRender();
};
