import find from "lodash/find";
import difference from "lodash/difference";
import queryString from "query-string";

const a = [
  "",
  "One ",
  "Two ",
  "Three ",
  "Four ",
  "Five ",
  "Six ",
  "Seven ",
  "Eight ",
  "Nine ",
  "Ten ",
  "Eleven ",
  "Twelve ",
  "Thirteen ",
  "Fourteen ",
  "Fifteen ",
  "Sixteen ",
  "Seventeen ",
  "Eighteen ",
  "Nineteen "
];
const b = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety"
];

export const inWords = num => {
  if ((num = num.toString()).length > 9) return "overflow";
  const n = `000000000${num}`
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  let str = "";
  str +=
    n[1] != 0
      ? `${a[Number(n[1])] || `${b[n[1][0]]} ${a[n[1][1]]}`}crore `
      : "";
  str +=
    n[2] != 0 ? `${a[Number(n[2])] || `${b[n[2][0]]} ${a[n[2][1]]}`}lakh ` : "";
  str +=
    n[3] != 0
      ? `${a[Number(n[3])] || `${b[n[3][0]]} ${a[n[3][1]]}`}thousand `
      : "";
  str +=
    n[4] != 0
      ? `${a[Number(n[4])] || `${b[n[4][0]]} ${a[n[4][1]]}`}hundred `
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || `${b[n[5][0]]} ${a[n[5][1]]}`)
      : "";
  return str;
};

export const isUrlValid = userInput => {
  const res = userInput.match(
    /^(http(s)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/
  );
  if (res == null) return false;
  else return true;
};

export const formatDate = (date = +Date.now()) => {
  let d = new Date(date),
    month = `${d.getMonth() + 1}`,
    day = `${d.getDate()}`,
    year = d.getFullYear(),
    hours = `${d.getHours()}`,
    minutes = `${d.getMinutes()}`;
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  if (hours.length < 2) hours = `0${hours}`;
  if (minutes.length < 2) minutes = `0${minutes}`;
  return `${[year, month, day].join("-")}T${[hours, minutes].join(":")}`;
};

export const returnRedirectionLink = (type, category, subCategory) => {
  category = category.replace(/ /g, "-");
  category = category.replace(/&/g, "and");
  category = category.toLowerCase();

  if (subCategory) {
    subCategory = subCategory.replace(/ /g, "-");
    subCategory = subCategory.replace(/&/g, "and");
    subCategory = subCategory.toLowerCase();
  }

  return subCategory
    ? `${type}/${category}/${subCategory}`
    : `${type}/${category}`;
};

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const isObject = value =>
  value && typeof value === "object" && value.constructor === Object;

export const isArray = value =>
  value && typeof value === "object" && value.constructor === Array;

export const isString = value =>
  typeof value === "string" || value instanceof String;

export const getDateTime = date => {
  const _date = new Date(date);
  return _date
    .toLocaleString([], {
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    })
    .toUpperCase();
};

export const removeSpaces = string => {
  string = string.replace(/ /g, "-");
  string = string.replace(/&/g, "and");
  string = string.replace(/\+/g, "plus");
  return string;
};

export const _urlFilterMap = url => {
  url = url && url.replace("categories-0", "hierarchicalCategories.lvl0");
  url = url && url.replace("categories-1", "hierarchicalCategories.lvl1");
  url = url && url.replace("categories-2", "hierarchicalCategories.lvl2");
  url = url && url.replace("occasion", "attributes.Occasion");
  url = url && url.replace("brand=", "brandName=");
  url = url && url.replace("new=", "IsNew=");
  url =
    url && url.replace("ideal_for", decodeURIComponent("attributes.Ideal For"));
  url =
    url && url.replace("color", decodeURIComponent("attributes.Color Family"));
  url =
    url &&
    url.replace("bottom_fit", decodeURIComponent("attributes.Bottom Fit"));
  url =
    url &&
    url.replace("dress_style", decodeURIComponent("attributes.Dress Style"));
  url =
    url &&
    url.replace("jacket_style", decodeURIComponent("attributes.Jacket style"));
  url =
    url &&
    url.replace("age_group", decodeURIComponent("attributes.Toys Age Group"));
  url =
    url &&
    url.replace(
      "jumpsuit_style",
      decodeURIComponent("attributes.Jumpsuit Type")
    );
  url =
    url &&
    url.replace(
      "sweater_style",
      decodeURIComponent("attributes.Sweater Style")
    );
  url =
    url &&
    url.replace(
      "lingerir_type",
      decodeURIComponent("attributes.Lingerie Type")
    );
  url =
    url &&
    url.replace(
      "sportswear_type",
      decodeURIComponent("attributes.Sportswear Type")
    );
  url =
    url &&
    url.replace("type_doll", decodeURIComponent("attributes.Type of Doll"));
  url =
    url &&
    url.replace("type_sport", decodeURIComponent("attributes.Type of Sport"));
  url =
    url &&
    url.replace("type_short", decodeURIComponent("attributes.Type of Short"));
  url =
    url &&
    url.replace("type_skirt", decodeURIComponent("attributes.Type of Skirt"));
  url =
    url &&
    url.replace(
      "flooring_type",
      decodeURIComponent("attributes.Type of Flooring")
    );
  url =
    url &&
    url.replace(
      "type_swimwear",
      decodeURIComponent("attributes.Type of Swimwear")
    );
  url =
    url &&
    url.replace(
      "type_softtoys",
      decodeURIComponent("attributes.Type of Soft Toys")
    );
  url =
    url &&
    url.replace(
      "type_sleepwear",
      decodeURIComponent("attributes.Type of Sleepwear")
    );
  url =
    url &&
    url.replace(
      "type_actiontoys",
      decodeURIComponent("attributes.Type of Action Toys")
    );
  url =
    url &&
    url.replace(
      "type_outdoorplay",
      decodeURIComponent("attributes.Type of Outdoor Play")
    );
  url =
    url &&
    url.replace(
      "type_gamespuzzles",
      decodeURIComponent("attributes.Type of Games & Puzzles")
    );
  url =
    url &&
    url.replace(
      "type_electronictoys",
      decodeURIComponent("attributes.Type of Electronic Toys")
    );
  url =
    url &&
    url.replace(
      "type_constructiontoys",
      decodeURIComponent("attributes.Type of Construction Toys")
    );
  url =
    url &&
    url.replace(
      "type_arts_creativeplay",
      decodeURIComponent("attributes.Type of Arts & Creative Play")
    );
  url =
    url &&
    url.replace(
      "type_baby_preschooltoys",
      decodeURIComponent("attributes.Type of Baby & Pre-School Toys")
    );
  url =
    url &&
    url.replace(
      "type_pretend_dressingup",
      decodeURIComponent("attributes.Type of Pretend Play & Dressing Up")
    );
  url =
    url &&
    url.replace("type_watch", decodeURIComponent("attributes.Types of Watch"));
  url =
    url &&
    url.replace(
      "type_kitchen",
      decodeURIComponent("attributes.Types of Kitchen")
    );
  url =
    url &&
    url.replace(
      "neckline_collar",
      decodeURIComponent("attributes.Types of Neckline & Collar")
    );
  url =
    url && url.replace("suit_fit", decodeURIComponent("attributes.Suit Fit"));
  url =
    url && url.replace("type_suit", decodeURIComponent("attributes.Suit Type"));
  url =
    url && url.replace("shirt_fit", decodeURIComponent("attributes.Shirt Fit"));
  url =
    url && url.replace("toe_style", decodeURIComponent("attributes.Toe Style"));
  url =
    url &&
    url.replace("short_style", decodeURIComponent("attributes.Short Style"));
  url =
    url &&
    url.replace("bedding_type", decodeURIComponent("attributes.Bedding Type"));
  url =
    url &&
    url.replace(
      "type_sleepwear",
      decodeURIComponent("attributes.Sleepwear Type")
    );
  url =
    url &&
    url.replace(
      "underwear_style",
      decodeURIComponent("attributes.Underwear Style")
    );
  url =
    url &&
    url.replace(
      "bath_accessories",
      decodeURIComponent("attributes.Bath Accessories")
    );
  url =
    url &&
    url.replace(
      "decoration_type",
      decodeURIComponent("attributes.Type of Decoration")
    );
  url = url && url.replace("storage", "attributes.Storage");
  url =
    url && url.replace("bed_size", decodeURIComponent("attributes.Bed Size"));
  url =
    url && url.replace("forgift", decodeURIComponent("attributes.For Gift"));
  url = url && url.replace("deocration", "attributes.Decoration");
  url =
    url &&
    url.replace("shoe_width", decodeURIComponent("attributes.Shoe Width"));
  url =
    url &&
    url.replace("heel_style", decodeURIComponent("attributes.Heel Style"));
  url =
    url &&
    url.replace("dial_shape", decodeURIComponent("attributes.Dial Shape"));
  url =
    url &&
    url.replace("frame_type", decodeURIComponent("attributes.Frame Type"));
  url =
    url &&
    url.replace("frame_shape", decodeURIComponent("attributes.Frame Shape"));
  url =
    url &&
    url.replace("shoe_closure", decodeURIComponent("attributes.Shoe Closure"));
  url =
    url &&
    url.replace("lens_feature", decodeURIComponent("attributes.Lens Feature"));
  url =
    url &&
    url.replace(
      "lens_material",
      decodeURIComponent("attributes.Lens Material")
    );
  url =
    url &&
    url.replace(
      "sleeve_length",
      decodeURIComponent("attributes.Sleeve Length")
    );
  url = url && url.replace("concentration", "attributes.Concentration");
  url =
    url &&
    url.replace(
      "watch_feature",
      decodeURIComponent("attributes.Watch Feature")
    );
  url =
    url &&
    url.replace(
      "recommended_age",
      decodeURIComponent("attributes.Age Recommended")
    );
  url =
    url &&
    url.replace(
      "kitchen_type",
      decodeURIComponent("attributes.Type of Kitchen")
    );
  url =
    url &&
    url.replace(
      "bag_straphandle",
      decodeURIComponent("attributes.Bag Strap/Handle")
    );
  url =
    url &&
    url.replace(
      "bag_liningmaterial",
      decodeURIComponent("attributes.Bag Lining Material")
    );
  url =
    url &&
    url.replace(
      "type_vehicleremotecontroltoys",
      decodeURIComponent("attributes.Type of Vehicles & Remote Control Toys")
    );
  url =
    url && url.replace("top_style", decodeURIComponent("attributes.Top Style"));
  url = url && url.replace("style", "attributes.Style");
  url = url && url.replace("size=", "sizeHierarchicalFilter.lvl2=");

  return url;
};

export const _validateRedirectionUrl = url => {
  if (url.charAt(0) === "/") {
    return url;
  } else {
    return "";
  }
};

export const formatUrlString = url => {
  return url.charAt(0) === "/" ? url : `/${url}`;
};

export const arrayReorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getNumberFromString = string => {
  const regExp = /[^0-9.]/g;
  const formatedString = string.replace(regExp, "");
  return !isNaN(formatedString) ? Number(formatedString) : null;
};

export const getContainerByFolderId = (pagesStructure, folderId) =>
  find(pagesStructure.pageContainers, container =>
    container.folderIds.includes(folderId)
  );

export const getPageRoute = (pagesStructure, pageId) => {
  const page = pagesStructure.pages[pageId];
  const folder = find(pagesStructure.folders, folder =>
    folder.pageIds.includes(page.id)
  );
  const container = getContainerByFolderId(pagesStructure, folder.id);
  return {
    page,
    folder,
    container
  };
};

export const getActivePageRoute = (pageId, history) => {
  const { search, pathname } = history.location;
  let parsedSearch = queryString.parse(search);
  return queryString.stringifyUrl({
    url: pathname,
    query: { ...parsedSearch, pageId }
  });
};

export const getNewAdded = (array, arrayToExclude) => {
  return difference(array, arrayToExclude)[0];
};

export const scrollIntoViewWithOffset = (element, offset = 45) => {
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

export const getDefaultUrlContentStructure = () => {
  return [
    {
      id: generateUUID(),
      title: "Top Section",
      slug: "TOP_SECTION",
      type: "dynamicBannerModule",
      enable: true,
      backgroundColor: "#3f3f3f",
      desktop: {
        rows: [],
        styles: {
          backgroundColor: "#FFFFFF",
          backgroundType: "backgroundColor",
          marginBottom: 0,
          marginTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }
      },
      mobile: {
        rows: [],
        styles: {
          backgroundColor: "#FFFFFF",
          backgroundType: "backgroundColor",
          marginBottom: 0,
          marginTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }
      },
      tablet: {
        rows: [],
        styles: {
          backgroundColor: "#FFFFFF",
          backgroundType: "backgroundColor",
          marginBottom: 0,
          marginTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }
      }
    },
    {
      id: generateUUID(),
      title: "Bottom Section",
      slug: "BOTTOM_SECTION",
      type: "dynamicBannerModule",
      enable: true,
      backgroundColor: "#3f3f3f",
      desktop: {
        rows: [],
        styles: {
          backgroundColor: "#FFFFFF",
          backgroundType: "backgroundColor",
          marginBottom: 0,
          marginTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }
      },
      mobile: {
        rows: [],
        styles: {
          backgroundColor: "#FFFFFF",
          backgroundType: "backgroundColor",
          marginBottom: 0,
          marginTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }
      },
      tablet: {
        rows: [],
        styles: {
          backgroundColor: "#FFFFFF",
          backgroundType: "backgroundColor",
          marginBottom: 0,
          marginTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }
      }
    }
  ];
};

export const validateExtraSpace = (value = "") => {
  if (value !== value.trim()) return false;
  return true;
};

export const delay = duration =>
  new Promise(resolve => setTimeout(resolve, duration));

export const getDefaultMenuContentStructure = () => ({
  english: {
    subTitle: "",
    fontStyle: "",
    textAlign: "",
    menuStyle: "",
    styles: {
      marginBottom: 0,
      marginTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0
    },
    textColor: "",
    menuType: "",
    redirectionUrl: ""
  },
  arabic: {
    subTitle: "",
    fontStyle: "",
    textAlign: "",
    menuStyle: "",
    menuType: "",
    textColor: "",
    styles: {
      marginBottom: 0,
      marginTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0
    },
    redirectionUrl: ""
  },
  authorization: false
});

export const isArabicContentEnabled = defaultConfig => {
  const isArabicRequired = {
    tablet: { isArabicEnabled: true },
    mobile: { isArabicEnabled: true },
    desktop: { isArabicEnabled: true }
  };
  const devices = ["tablet", "mobile", "desktop"];
  const types = ["resp", "app"];
  devices.forEach(device => {
    if (device === devices[0] || device === devices[1]) {
      let isArabicEnabled = [];
      types.forEach(type => {
        if (defaultConfig?.children?.[device]?.[type]?.length) {
          const isArabicEnabledObj = defaultConfig.children[device][type].find(
            configObj => {
              return configObj?.["english"]?.["key"] === "isArabicEnabled";
            }
          );
          if (isArabicEnabledObj) {
            let isDisabled =
              isArabicEnabledObj["english"]["value"] === "false" ||
              isArabicEnabledObj["english"]["value"] === false;
            isDisabled
              ? isArabicEnabled.push(false)
              : isArabicEnabled.push(true);
          } else {
            isArabicEnabled.push(true);
          }
        } else {
          isArabicEnabled.push(true);
        }
      });
      isArabicEnabled.length && isArabicEnabled.every(val => val === false)
        ? (isArabicRequired[device]["isArabicEnabled"] = false)
        : (isArabicRequired[device]["isArabicEnabled"] = true);
    } else {
      let isArabicEnabled = [];
      if (defaultConfig?.children?.[device]?.length) {
        const isArabicEnabledObj = defaultConfig.children[device].find(
          configObj => {
            return configObj?.["english"]?.["key"] === "isArabicEnabled";
          }
        );
        if (isArabicEnabledObj) {
          let isDisabled =
            isArabicEnabledObj["english"]["value"] === "false" ||
            isArabicEnabledObj["english"]["value"] === false;
          isDisabled ? isArabicEnabled.push(false) : isArabicEnabled.push(true);
        } else {
          isArabicEnabled.push(true);
        }
      } else {
        isArabicEnabled.push(true);
      }
      isArabicEnabled.length && isArabicEnabled.every(val => val === false)
        ? (isArabicRequired[device]["isArabicEnabled"] = false)
        : (isArabicRequired[device]["isArabicEnabled"] = true);
    }
  });
  return isArabicRequired;
};
export const convertDataToCSVFormt = (headerArray = [], keyArray = [], data = []) => {
  let finalData = [headerArray];

  data.forEach((item) => {
    let rowData = [];
    keyArray.forEach((key) => {
      rowData.push(item[key] || "");
    });
    finalData.push(rowData);
  });
  return finalData;
}

