import React from "react";
import {
  countdowndimentionInputs,
  countdownPositionInputs
} from "../../constants/stylingModal";
import { TextField, Box, Typography } from "../../materialUi/components";

const CountdownStylingControls = ({
  styles,
  handleSpacingChange,
  positionStyle,
  dimentionStyle,
  CommonStyle
}) => {
  const updateStyles = (name, value) => {
    if (!Number.isInteger(value)) {
      return;
    }
    if (value > 100) value = 100;
    else if (value < 0) value = 0;

    handleSpacingChange({
      ...styles,
      [name]: value
    });
  };

  return (
    <Box sx={{ ...CommonStyle }}>
      <Box sx={{ ...positionStyle }}>
        <Typography sx={{ width: "100%", marginBottom: "10px" }}>
          Countdown Position (%)
        </Typography>
        <Box sx={{ width: "100%", display: "flex" }}>
          {countdownPositionInputs.map(({ name, label }, idx) => (
            <TextField
              type="Number"
              key={idx}
              label={label}
              name={name}
              placeholder={label}
              value={styles[name]}
              onChange={e => updateStyles(name, +e.target.value)}
              size="small"
              sx={{
                width: `${Math.floor(100 / countdownPositionInputs.length)}%`,
                marginRight: "5px"
              }}
              InputLabelProps={{ shrink: true }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ ...dimentionStyle }}>
        <Typography sx={{ width: "100%", marginBottom: "10px" }}>
          Countdown W x H (%)
        </Typography>
        <Box sx={{ width: "100%", display: "flex" }}>
          {countdowndimentionInputs.map(({ name, label }, idx) => (
            <TextField
              type="Number"
              key={idx}
              label={label}
              name={name}
              placeholder={label}
              value={styles[name]}
              onChange={e => updateStyles(name, +e.target.value)}
              size="small"
              sx={{
                width: `${Math.floor(100 / countdowndimentionInputs.length)}%`,
                marginRight: "5px"
              }}
              InputLabelProps={{ shrink: true }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CountdownStylingControls;
