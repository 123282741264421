import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import set from "lodash/set";
import { cloneDeep } from "lodash";
import {
  deviceCheckboxes,
  deviceTypes,
  SLIDER_IMAGE_ACCORDION_PLACEHOLDERS,
  imageModalAttributes
} from "../../../constants";
import {
  Button,
  Box,
  TextField,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  BasicPopover,
  Slider,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete
} from "../../../materialUi/components";
import { DeleteIcon } from "../../../materialUi/icons";
import { bannerVideoSchema } from "../../../util/homepage";
import { UploadVideoModal } from "./UploadVideoModal";
import SpacingControls from "../SpacingControlsMUI";
import { VideoSelectionModal } from "../../../containers/modal/videoSelectionModalMUI";
import { toastMsg, snackbar } from "../../../actions/common.action";
import {
  putBannerDataStyles,
  updateBannerRowItemData,
  changeBannerImageHoverStyle,
  fetchRedirectionUrlList
} from "../../../actions/dynamicBanner.action";

export const VideoModal = ({
  isOpen,
  handleClose,
  handleSetModalOpen,
  item,
  deviceType,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  path
}) => {
  const dispatch = useDispatch();
  const multiLanguageConfig = useSelector(
    state => state.common.multiLanguageConfig
  );
  const [videoData, setVideoData] = useState(cloneDeep(item));
  const styles = get(videoData, "styles", {});
  const attributes = get(videoData, "attributes", []);
  const [imageSelectionModal, setImageSelectionModal] = useState(false);
  const [language, setLanguage] = useState(null);
  const [attributeList, setAttributeList] = useState([]);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [attributeRowIndex, setAttributeRowIndex] = useState(-1);
  const [redirectionUrlOptions, setRedirectionUrlOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVideoData(cloneDeep(item));
  }, [item]);

  useMemo(() => {
    const list = imageModalAttributes.map(item => {
      const disabled = attributes.some(({ key }) => key === item.value);
      return { ...item, disabled };
    });
    const selectedAttributeIndex = list.findIndex(
      item => item.disabled === false
    );
    const selectedValue =
      selectedAttributeIndex > -1 ? list[selectedAttributeIndex].value : "";
    setSelectedAttributeValue(selectedValue);
    setAttributeList(list);
  }, []);

  useMemo(() => {
    const list = imageModalAttributes.map(item => {
      const disabled = attributes.some(({ key }) => key === item.value);
      return { ...item, disabled };
    });
    const selectedAttributeIndex = list.findIndex(
      item => item.disabled === false
    );
    const selectedValue =
      selectedAttributeIndex > -1 ? list[selectedAttributeIndex].value : "";
    setSelectedAttributeValue(selectedValue);
    setAttributeList(list);
  }, [attributes.length]);

  const updateRowImageData = ({ name, value, language }) => {
    const _videoData = cloneDeep(videoData);
    set(_videoData, language ? [language, name] : [name], value);
    setVideoData(_videoData);
  };

  const addAttributeRowImageData = index => {
    const _videoData = cloneDeep(videoData);
    const value = { key: selectedAttributeValue, value: "" };
    set(_videoData, ["attributes", index], value);
    setVideoData(_videoData);
  };

  const updateAttributeRowImageData = (index, name, value) => {
    const _videoData = cloneDeep(videoData);
    set(_videoData, ["attributes", index, name], value);
    setVideoData(_videoData);
  };

  const removeAttributeRowImageData = index => {
    const newAttributeList = cloneDeep(attributes);
    newAttributeList.splice(index, 1);
    const _videoData = cloneDeep(videoData);
    set(_videoData, ["attributes"], newAttributeList);
    setVideoData(_videoData);
  };

  const handleVideoUploadSuccess = (videoUrl, imageLanguage, fileName) => {
    let video = document.createElement("video");
    video.setAttribute("src", videoUrl);

    video.onloadeddata = event => {
      const { videoHeight, videoWidth } = event.target;

      const _videoData = cloneDeep(videoData);
      set(_videoData, [imageLanguage, "videoUrl"], videoUrl);
      set(
        _videoData,
        [imageLanguage, "ratio"],
        (videoHeight / videoWidth) * 100
      );

      if (fileName) {
        set(_videoData, ["bannerTitle"], fileName);
      }
      setVideoData(_videoData);
      setImageSelectionModal(false);
      setLanguage(null);
      dispatch(
        snackbar({
          open: true,
          severity: "success",
          text: "Video was successfully uploaded"
        })
      );
    };

    video.onerror = () => {
      dispatch(
        snackbar({
          open: true,
          severity: "error",
          text: "Error while uploading Video"
        })
      );
    };
  };

  const deleteVideo = imageLanguage => {
    const _videoData = cloneDeep(videoData);
    set(_videoData, [imageLanguage, "videoUrl"], "");
    set(_videoData, [imageLanguage, "ratio"], "");
    setVideoData(_videoData);
  };

  const handleImageSelectionModal = language => {
    setLanguage(language);
    setImageSelectionModal(!imageSelectionModal);
  };

  const handleChangeEnabled = ({ name, value, language }) => {
    const enabledOn = get(videoData, `${language}.enabledOn`, []);

    enabledOn.indexOf(name) === -1 && value
      ? enabledOn.push(name)
      : enabledOn.splice(enabledOn.indexOf(name), 1);

    updateRowImageData({
      name: "enabledOn",
      value: enabledOn,
      language
    });
  };

  const showDeviceCheckboxes = useMemo(
    () => deviceType !== deviceTypes.desktop,
    [deviceType]
  );
  const validateImageModalBlock = () => {
    bannerVideoSchema(
      !showDeviceCheckboxes,
      multiLanguageConfig[deviceType]["isArabicEnabled"]
    )
      .validate(videoData)
      .then(() => {
        dispatch(toastMsg("success", "Successfully validated"));

        updateRowImageData({
          name: "validated",
          value: true
        });

        dispatch(
          updateBannerRowItemData({
            path,
            value: cloneDeep(videoData),
            pageType,
            updateCatalogPageType,
            updateCatalogPageLocation
          })
        );

        handleSetModalOpen(false);
      })
      .catch(err => {
        dispatch(toastMsg("warning", err.errors.join(",")));
      });
  };

  const handleSpacingChange = value => {
    const _videoData = cloneDeep(videoData);
    set(_videoData, ["styles"], value);
    setVideoData(_videoData);
  };

  const handleChangeHoverStyles = (key, value, maxLength) => {
    if (value > maxLength) value = maxLength;
    else if (value < 0) value = 0;
    const _videoData = cloneDeep(videoData);
    value = +Number(value).toFixed(3);
    set(_videoData, ["styles", "hoverValues", key], value);
    let formattedValue;

    switch (key) {
      case "transform": {
        formattedValue = `scale(${value})`;
        break;
      }
      case "transition": {
        formattedValue = `transform ${value}s`;
        break;
      }

      default: {
        formattedValue = value;
      }
    }

    set(_videoData, ["styles", "hover", key], formattedValue);
    setVideoData(_videoData);
  };

  const handleChangeAttributeType = event => {
    setSelectedAttributeValue(event.target.value || "");
  };

  const handleAddAttributeRow = () => {
    addAttributeRowImageData(attributes.length);
  };

  const handleUpdateAttributeRow = (index, name, value) => {
    updateAttributeRowImageData(index, name, value);
  };

  const handleRemoveAttributeRow = index => {
    removeAttributeRowImageData(index);
  };

  const getRedirectionUrlList = async text => {
    setLoading(true);
    const response = await dispatch(fetchRedirectionUrlList(10, 0, text));
    let newList = response?.data?.data || [];
    newList = newList.map(({ url }) => url);
    setRedirectionUrlOptions([...new Set(newList)]);
    setLoading(false);
  };

  const handleModalClose = () => {
    setVideoData(cloneDeep(item));
    handleClose();
  };
  const opacity = get(styles, "hoverValues.opacity", 0);
  const transform = get(styles, "hoverValues.transform", 0);
  const transition = get(styles, "hoverValues.transition", 0);
  return (
    <div className="banner-image-modal">
      {imageSelectionModal && (
        <VideoSelectionModal
          title="Upload Video"
          modalName={"videoSelectionModal"}
          containerName={"dynamicBanner"}
          language={language}
          keyName="modal"
          onHide={() => setImageSelectionModal(false)}
          onSuccess={handleVideoUploadSuccess}
          isOpen={imageSelectionModal}
          handleClose={() => setImageSelectionModal(false)}
        />
      )}
      {Boolean(anchorEl) && (
        <BasicPopover
          open={Boolean(anchorEl)}
          id={attributeRowIndex}
          anchorEl={anchorEl}
          handleCancel={() => {
            setAnchorEl(null);
            setAttributeRowIndex(-1);
          }}
          handleSave={() => {
            handleRemoveAttributeRow(attributeRowIndex);
            setAnchorEl(null);
            setAttributeRowIndex(-1);
          }}
          text={"Are you sure you want to delete the row?"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
        />
      )}
      <Dialog open={isOpen} onClose={handleClose} maxWidth={"lg"} fullWidth>
        <DialogTitle>{"Video"}</DialogTitle>
        <DialogContent dividers>
          <Box>
            <Box>
              <div className="banner-wrapper">
                <div className="banner-english-wrapper">
                  <Autocomplete
                    inputValue={get(videoData, `english.redirectionLink`, "")}
                    onInputChange={(e, newInputValue) => {
                      updateRowImageData({
                        name: "redirectionLink",
                        value: newInputValue || "",
                        language: "english"
                      });
                      if (
                        newInputValue?.length &&
                        newInputValue.length % 3 === 0
                      )
                        getRedirectionUrlList(newInputValue);
                    }}
                    loading={loading}
                    freeSolo
                    options={redirectionUrlOptions}
                    sx={{ width: "100%" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Redirection Link(must start with '/')"
                        size="small"
                        required
                        lang="english"
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          SLIDER_IMAGE_ACCORDION_PLACEHOLDERS.english
                            .redirectionLink
                        }
                      />
                    )}
                    onBlur={() => {
                      setRedirectionUrlOptions([]);
                      setLoading(false);
                    }}
                  />
                  {showDeviceCheckboxes && (
                    <Box>
                      {deviceCheckboxes.map(({ name, label }) => {
                        const enabledOn = get(
                          videoData,
                          `english.enabledOn`,
                          []
                        );
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                key={name}
                                name={name}
                                checked={enabledOn.includes(name)}
                                onChange={e =>
                                  handleChangeEnabled({
                                    name: e.target.name,
                                    value: e.target.checked,
                                    language: "english"
                                  })
                                }
                              />
                            }
                            label={label}
                          />
                        );
                      })}
                    </Box>
                  )}
                  <UploadVideoModal
                    item={videoData}
                    language="english"
                    handleVideoSelectionModal={() =>
                      handleImageSelectionModal("english")
                    }
                    deleteVideo={deleteVideo}
                  />
                  {get(videoData, "english.videoUrl") && (
                    <a href={get(videoData, "english.videoUrl")}>Video link</a>
                  )}
                </div>
                <div className="banner-arabic-wrapper">
                  <Autocomplete
                    inputValue={get(videoData, `arabic.redirectionLink`, "")}
                    onInputChange={(e, newInputValue) => {
                      updateRowImageData({
                        name: "redirectionLink",
                        value: newInputValue || "",
                        language: "arabic"
                      });
                      if (
                        newInputValue?.length &&
                        newInputValue.length % 3 === 0
                      )
                        getRedirectionUrlList(newInputValue);
                    }}
                    loading={loading}
                    freeSolo
                    options={redirectionUrlOptions}
                    sx={{ width: "100%" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Redirection Link(must start with '/')"
                        size="small"
                        required
                        lang="arabic"
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          SLIDER_IMAGE_ACCORDION_PLACEHOLDERS.arabic
                            .redirectionLink
                        }
                      />
                    )}
                    onBlur={() => {
                      setRedirectionUrlOptions([]);
                      setLoading(false);
                    }}
                  />
                  {showDeviceCheckboxes && (
                    <Box>
                      {deviceCheckboxes.map(({ name, label }) => {
                        const enabledOn = get(
                          videoData,
                          `arabic.enabledOn`,
                          []
                        );
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                key={name}
                                name={name}
                                checked={enabledOn.includes(name)}
                                onChange={e =>
                                  handleChangeEnabled({
                                    name: e.target.name,
                                    value: e.target.checked,
                                    language: "arabic"
                                  })
                                }
                              />
                            }
                            label={label}
                          />
                        );
                      })}
                    </Box>
                  )}
                  <UploadVideoModal
                    item={videoData}
                    language="arabic"
                    handleVideoSelectionModal={() =>
                      handleImageSelectionModal("arabic")
                    }
                    deleteVideo={deleteVideo}
                  />
                  {get(videoData, "arabic.videoUrl") && (
                    <a href={get(videoData, "arabic.videoUrl")}>Video link</a>
                  )}
                </div>
              </div>
            </Box>
            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "50%", paddingRight: "25px" }}>
                <Box sx={{ marginBottom: "10px" }}>
                  <TextField
                    type="text"
                    name={"bannerId"}
                    value={videoData.bannerId || ""}
                    onChange={e =>
                      updateRowImageData({
                        name: "bannerId",
                        value: e.target.value || ""
                      })
                    }
                    size="small"
                    variant="outlined"
                    label={"Banner Id"}
                    placeholder="Please Enter Banner Id"
                    InputLabelProps={{ shrink: true }}
                    required
                    sx={{ width: "49%" }}
                  />
                  <TextField
                    type="text"
                    name={"bannerTitle"}
                    value={videoData.bannerTitle || ""}
                    label={"Banner Title"}
                    placeholder="Please Enter Banner Title"
                    onChange={e =>
                      updateRowImageData({
                        name: "bannerTitle",
                        value: e.target.value || ""
                      })
                    }
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    required
                    sx={{ width: "49%", float: "right" }}
                  />
                </Box>
                <SpacingControls
                  styles={styles}
                  handleSpacingChange={handleSpacingChange}
                  CommonStyle={{ display: "flex", width: "100%" }}
                  paddingStyle={{ width: "70%" }}
                  marginStyle={{ width: "30%", marginLeft: "20px" }}
                />
                <Box sx={{ marginTop: "10px" }}>
                  <Typography variant="h6">Hover</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px"
                    }}
                  >
                    <Typography sx={{ width: "10%" }}>Opacity</Typography>
                    <Slider
                      value={opacity}
                      max={1}
                      min={0}
                      step={0.1}
                      onChange={(_, value) =>
                        handleChangeHoverStyles("opacity", value, 1)
                      }
                      size="medium"
                      sx={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "80%"
                      }}
                    />
                    <TextField
                      type="Number"
                      name={"opacity"}
                      value={opacity}
                      onChange={e =>
                        handleChangeHoverStyles("opacity", e.target.value, 1)
                      }
                      size="small"
                      sx={{ width: "10%" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px"
                    }}
                  >
                    <Typography sx={{ width: "10%" }}>Transform</Typography>
                    <Slider
                      value={transform}
                      max={2}
                      min={0}
                      step={0.025}
                      onChange={(_, value) =>
                        handleChangeHoverStyles("transform", value, 2)
                      }
                      sx={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "80%"
                      }}
                    />
                    <TextField
                      type="Number"
                      name={"transform"}
                      value={transform}
                      onChange={e =>
                        handleChangeHoverStyles("transform", e.target.value, 2)
                      }
                      size="small"
                      sx={{ width: "10%" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px"
                    }}
                  >
                    <Typography sx={{ width: "10%" }}>Transition</Typography>
                    <Slider
                      value={transition}
                      max={2}
                      min={0}
                      step={0.1}
                      onChange={(_, value) =>
                        handleChangeHoverStyles("transition", value, 2)
                      }
                      sx={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "80%"
                      }}
                    />
                    <TextField
                      type="Number"
                      name={"transition"}
                      value={transition}
                      onChange={e =>
                        handleChangeHoverStyles("transition", e.target.value, 2)
                      }
                      size="small"
                      sx={{ width: "10%" }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  border: "1px solid rgba(0, 0, 0, 0.12)"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      marginTop: "32px",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <TextField
                      select
                      label="Add Additional Attributes"
                      onChange={handleChangeAttributeType}
                      placeholder="Please Select Attribute type"
                      size="small"
                      sx={{
                        width: "50%",
                        marginLeft: "auto",
                        marginRight: "10px"
                      }}
                      value={selectedAttributeValue}
                      InputLabelProps={{ shrink: true }}
                    >
                      {attributeList.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      variant="outlined"
                      onClick={handleAddAttributeRow}
                      size="small"
                      disabled={
                        attributes.length === imageModalAttributes.length
                      }
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{ width: "100%", overflow: "hidden", marginTop: "42px" }}
                >
                  <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
                    <Table aria-label="a dense table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            key={"key"}
                            align={"center"}
                            style={{ backgroundColor: "#f1f1f1" }}
                          >
                            {"Key"}
                          </TableCell>
                          <TableCell
                            key={"value"}
                            align={"center"}
                            style={{ backgroundColor: "#f1f1f1" }}
                          >
                            {"Value"}
                          </TableCell>
                          <TableCell
                            key={"action"}
                            align={"center"}
                            style={{ backgroundColor: "#f1f1f1" }}
                          >
                            {"Action"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attributes.map(({ key, value }, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            style={{ maxHeight: "15px" }}
                          >
                            <TableCell key={"key"} align={"left"} width={"30%"}>
                              <TextField
                                value={key}
                                style={{ width: "100%" }}
                                size="small"
                                disabled
                              />
                            </TableCell>
                            <TableCell
                              key={"value"}
                              align={"left"}
                              width={"60%"}
                            >
                              <TextField
                                onChange={ev =>
                                  handleUpdateAttributeRow(
                                    index,
                                    "value",
                                    get(ev, "target.value", null)
                                  )
                                }
                                value={value}
                                style={{ width: "100%" }}
                                size="small"
                              />
                            </TableCell>
                            <TableCell
                              key={"action"}
                              align={"center"}
                              width={"10%"}
                            >
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  aria-label="delete"
                                  onClick={ev => {
                                    setAnchorEl(ev.currentTarget);
                                    setAttributeRowIndex(index);
                                  }}
                                  size="small"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModalClose}
            variant="outlined"
            sx={{ marginRight: "auto" }}
          >
            Close
          </Button>
          <Button onClick={validateImageModalBlock} variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
