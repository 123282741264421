import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _, { cloneDeep } from "lodash";
import { DownloadIcon } from "../../materialUi/icons";
import moment from "moment";
import { CSVLink } from "react-csv";
import { snackbar } from "../../actions/common.action";
import { fetchVoucherLogData } from "../../actions/voucherLog.action";

import {
  Button,
  TextField,
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Typography,
  TablePagination
} from "../../materialUi/components";
import { DateTimePicker } from "../../materialUi/datePicker";
import { convertDataToCSVFormt } from "../../util";

function UpdatePageContent({}) {
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [voucherLogs, setVoucherLogs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleSubmit = async (pageNumber, rowsPerPage) => {
    if (moment(fromDate) > moment(toDate)) {
      return dispatch(
        snackbar({
          open: true,
          severity: "error",
          text: "From Date should be lesser or equal to To Date "
        })
      );
    }
    if (moment(fromDate) > moment()) {
      return dispatch(
        snackbar({
          open: true,
          severity: "error",
          text: "From Date should be lesser or equal to Current Date"
        })
      );
    }
    const limit = rowsPerPage;
    const skip = pageNumber * rowsPerPage;
    const response = await dispatch(
      fetchVoucherLogData(
        new Date(Date.parse(fromDate)),
        new Date(Date.parse(toDate)),
        limit,
        skip
      )
    );
    if (response.status === 200 && response.data?.data?.result) {
      setVoucherLogs(response.data.data.result.logs || []);
      setTotalCount(response.data.data.result.totalCount || 0);
      setPageNumber(pageNumber);
      setRowsPerPage(rowsPerPage);
    }
  };

  const handleChangePage = (event, pageNumber) => {
    handleSubmit(pageNumber, rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    handleSubmit(0, parseInt(event.target.value, 10));
  };

  return (
    <Box sx={{ margin: "20px 20px 20px 20px" }}>
      <Box sx={{ marginTop: "5px", marginBottom: "5px" }}>
        <DateTimePicker
          label="From Date"
          value={moment(fromDate)}
          onChange={val => {
            setFromDate(val ? moment(val).format() : val);
          }}
          renderInput={params => <TextField {...params} />}
          disableFuture
          sx={{ minWidth: "30%", marginRight: "20px" }}
          slotProps={{ textField: { size: "small", error: false } }}
        />
        <DateTimePicker
          label="To Date"
          value={moment(toDate)}
          onChange={val => {
            setToDate(val ? moment(val).format() : val);
          }}
          renderInput={params => <TextField {...params} />}
          sx={{ minWidth: "30%", marginLeft: "20px", marginRight: "20px" }}
          maxDate={moment()}
          slotProps={{ textField: { size: "small", error: false } }}
        />

        <Button
          variant="contained"
          onClick={() => handleSubmit(0, rowsPerPage)}
          disabled={!fromDate || !toDate}
        >
          Submit
        </Button>
        <CSVLink
          data={convertDataToCSVFormt(
            [
              "Voucher Code",
              "Customer Id",
              "Platform",
              "IP Address",
              "Date",
              "Status",
              "Error Message"
            ],
            [
              "voucherCode",
              "customerId",
              "platform",
              "ipAddress",
              "createdAt",
              "status",
              "errorMessage"
            ],
            cloneDeep(voucherLogs)
          )}
          filename={`${new Date()}-reviews.csv`}
        >
          <Tooltip title="Export to CSV" arrow placement="top">
            <IconButton disabled={false}>
              <DownloadIcon sx={{ color: "black" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </CSVLink>
      </Box>
      <Divider
        variant="fullWidth"
        sx={{ marginTop: "16px", marginBottom: "16px" }}
      />
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  key={"index"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"S.No"}
                </TableCell>
                <TableCell
                  key={"voucherCode"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Voucher Code"}
                </TableCell>
                <TableCell
                  key={"customerId"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Customer Id"}
                </TableCell>
                <TableCell
                  key={"platform"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Platform"}
                </TableCell>
                <TableCell
                  key={"ipAddress"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"IP Address"}
                </TableCell>
                <TableCell
                  key={"createdAt"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Date"}
                </TableCell>
                <TableCell
                  key={"status"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Status"}
                </TableCell>
                <TableCell
                  key={"errorMessage"}
                  align={"left"}
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {"Error Message"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {voucherLogs.map((item, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  style={{ maxHeight: "15px" }}
                >
                  <TableCell key={"index"} align={"left"}>
                    {index + 1}
                  </TableCell>
                  <TableCell key={"voucherCode"} align={"left"}>
                    {item.voucherCode || ""}
                  </TableCell>
                  <TableCell key={"customerId"} align={"left"}>
                    {item.customerId || ""}
                  </TableCell>
                  <TableCell key={"platform"} align={"left"}>
                    {item.platform || ""}
                  </TableCell>
                  <TableCell key={"ipAddress"} align={"left"}>
                    {item.ipAddress || ""}
                  </TableCell>
                  <TableCell key={"createdAt"} align={"left"}>
                    {item.createdAt
                      ? new Date(item.createdAt).toDateString()
                      : ""}
                  </TableCell>
                  <TableCell key={"status"} align={"left"}>
                    {item.status || ""}
                  </TableCell>
                  <TableCell key={"errorMessage"} align={"left"}>
                    {item.errorMessage || ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!voucherLogs.length ? (
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px"
            }}
          >
            No Records Found
          </Typography>
        ) : (
          <TablePagination
            component="div"
            count={totalCount}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                marginTop: "1em",
                marginBottom: "1em"
              }
            }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        )}
      </Paper>
    </Box>
  );
}

export default UpdatePageContent;
