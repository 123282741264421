import React from "react";
import UpdatePageContent from "./updatePageContent";

function Content({ accessRights }) {
  return (
    <div
      className="content-container-wrapper"
      style={{ backgroundColor: "#ffffff" }}
    >
      <UpdatePageContent accessRights={accessRights} />
    </div>
  );
}

export default Content;
