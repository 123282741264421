export const paddingInputs = [
  { name: "paddingTop", label: "TOP" },
  { name: "paddingRight", label: "RIGHT" },
  { name: "paddingBottom", label: "BOTTOM" },
  { name: "paddingLeft", label: "LEFT" }
];

export const marginInputs = [
  { name: "marginTop", label: "TOP" },
  // { name: 'marginRight', label: 'RIGHT' },
  { name: "marginBottom", label: "BOTTOM" }
  // { name: 'marginLeft', label: 'LEFT' },
];

export const countdownPositionInputs = [
  { name: "top", label: "TOP" },
  { name: "right", label: "RIGHT" },
  { name: "left", label: "LEFT" },
  { name: "bottom", label: "BOTTOM" }
];

export const countdowndimentionInputs = [
  { name: "width", label: "WIDTH" },
  { name: "height", label: "HEIGHT" }
];
