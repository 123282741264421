export const CHANGE_TAB = "CHANGE_TAB";
export const COMMON_SET_SUBMIT_STATE = "COMMON_SET_SUBMIT_STATE";
export const HIDE_LOADER = "HIDE_LOADER";
export const SELECT_COUNTRY_ID = "SELECT_COUNTRY_ID";
export const RESET_COUNTRY_ID = "RESET_COUNTRY_ID";
export const SHOW_LOADER = "SHOW_LOADER";
export const SELECT_STORE_ID = "SELECT_STORE_ID";
export const RESET_STORE_ID = "RESET_STORE_ID";
export const SET_ACCORDION_STATE = "SET_ACCORDION_STATE";
export const SHOW_CUSTOM_LOADER = "SHOW_CUSTOM_LOADER";
export const HIDE_CUSTOM_LOADER = "HIDE_CUSTOM_LOADER";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const SET_DEFAULT_CONFIGURATIONS = "SET_DEFAULT_CONFIGURATIONS";
export const RESET_DEFAULT_CONFIGURATIONS = "RESET_DEFAULT_CONFIGURATIONS";
export const BROWSER_STORAGE = {
  ACCESS_TOKEN: "access_token",
  COOKIE_EXPIRY_TIME: 60 * 60 * 23
};
