import React from "react";
import Content from "./content";

function RightPanel({ accessRights }) {
  return (
    <div
      className="right-wrapper"
      style={{ backgroundColor: "#ffffff", marginBottom: "10px" }}
    >
      <Content accessRights={accessRights} />
    </div>
  );
}

export default RightPanel;
