import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import isEqual from 'lodash/isEqual';
import map from "lodash/map";
import get from "lodash/get";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { CATALOG_COMPONENT_TYPES, PAGES_PAGE } from "../../constants";
import {
  updatePageData,
  openComponent,
  deleteComponent,
  toggleSection,
  reorderComponents,
  selectNewPageFolder,
  setNewPageFolder
} from "../../actions/pagesPage.action";

import { _validateRedirectionUrl, arrayReorder } from "../../util";
// import UpdateCatalogBanner from './updateCatalogBanner';
// import UpdateCarouselTypeA from './updateCarouselTypeA';
// import UpdateCarouselTypeB from './updateCarouselTypeB';
// import { addNewPagevalidation } from './validation';
import UpdateGeneralSeoContent from "./updateGeneralSeoContent";
import PageContentForm from "./pageContentForm";
import { DynamicBannerContent } from "../../component";
import UpdateCatalogBanner from "./updateCatalogBanner";
import UpdateCarouselTypeA from "./updateCarouselTypeA";
import UpdateCarouselTypeB from "./updateCarouselTypeB";
import {
  selectFoldersByRouteFolder,
  selectNewFolder,
  selectPagesRouteFolder
} from "../../selectors";

const UpdatePageContent = ({
  updatePageData,
  updatePageContent,
  reorderComponents,
  openComponent,
  toggleSection,
  deleteComponent,
  selectNewPageFolder,
  setNewPageFolder,
  initialFolder,
  folders,
  newFolder,
  accessRights
}) => {
  const handleTextChange = e => {
    let url = "";
    if (e.target.name === "url") {
      if (_validateRedirectionUrl(e.target.value)) {
        url = e.target.value.toLowerCase();
      } else {
        url = `/${e.target.value.toLowerCase()}`;
      }
    }

    updatePageData([e.target.name], url || e.target.value);
  };

  const handleAuthorizationChange = e =>
    updatePageData([e.target.name], e.target.checked);

  const handleisGamingURL = e =>
    updatePageData([e.target.name], e.target.checked);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const content = arrayReorder(
      updatePageContent.content,
      result.source.index,
      result.destination.index
    );

    reorderComponents({ content });
  };

  const handleComponentOpen = (id, index) => {
    openComponent({ id, index });
  };

  const handleToggleSection = index => {
    toggleSection({ index });
  };

  const handleDeleteItem = index => {
    deleteComponent({ index });
  };

  const getComponent = (type, { index, pageContent }) => {
    // TODO This is for the old banner's structure, should be removed in the future, when we'll have no old structure banners in the database
    if (pageContent.data) {
      pageContent = { ...pageContent.data, ...pageContent };
    }

    const isOpen = get(pageContent, "openAccordion", false);

    if (pageContent) {
      switch (type) {
        case CATALOG_COMPONENT_TYPES.DYNAMIC_BANNER: {
          return (
            <DynamicBannerContent
              content={pageContent}
              handleDeleteSection={() => handleDeleteItem(index)}
              handleExpandSection={() => handleComponentOpen(pageContent.id)}
              handleToggleSection={() => handleToggleSection(index)}
              keyIndex={index}
              listName="imageList"
              sliderId="dynamic_banner"
              accordionTitle={pageContent.title}
              pageType={PAGES_PAGE}
              accessRights={accessRights}
            />
          );
        }
        case CATALOG_COMPONENT_TYPES.BANNER:
          return (
            <UpdateCatalogBanner
              position={index}
              content={pageContent}
              isOpen={isOpen}
              handleOpen={() => handleComponentOpen(pageContent.id, index)}
            />
          );
        case CATALOG_COMPONENT_TYPES.IMAGE_CAROUSEL:
          return (
            <UpdateCarouselTypeA
              position={index}
              content={pageContent}
              isOpen={isOpen}
              handleOpen={() => handleComponentOpen(pageContent.id, index)}
            />
          );
        case CATALOG_COMPONENT_TYPES.PRODUCT_CAROUSEL:
          return (
            <UpdateCarouselTypeB
              position={index}
              content={pageContent}
              isOpen={isOpen}
              handleOpen={() => handleComponentOpen(pageContent.id, index)}
            />
          );
      }
    }
  };

  const handleSaveExternalLinks = (key, value) =>
    updatePageData([key], value || []);

  return (
    <Fragment>
      <div className="banner-catalog-dnd">
        <PageContentForm
          content={updatePageContent}
          handleTextChange={handleTextChange}
          handleAuthorizationChange={handleAuthorizationChange}
          selectNewPageFolder={selectNewPageFolder}
          setNewPageFolder={setNewPageFolder}
          initialFolder={initialFolder}
          folders={folders}
          newFolder={newFolder}
          handleSaveExternalLinks={handleSaveExternalLinks}
          handleisGamingURL={handleisGamingURL}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <UpdateGeneralSeoContent
                  seoContent={updatePageContent.meta}
                  pageType={PAGES_PAGE}
                />
                {map(updatePageContent.content, (pageContent, index) => (
                  <div className={pageContent.type} key={index}>
                    {getComponent(pageContent.type, {
                      pageContent,
                      index
                    })}
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  initialFolder: selectPagesRouteFolder(state),
  folders: selectFoldersByRouteFolder(state),
  newFolder: selectNewFolder(state)
});

const mapDispatchToProps = {
  updatePageData,
  openComponent,
  deleteComponent,
  toggleSection,
  reorderComponents,
  selectNewPageFolder,
  setNewPageFolder
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdatePageContent)
);
