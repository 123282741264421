import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import {
  CATALOG_COMPONENT_TYPES,
  PAGES_PANEL,
  MESSAGES,
  ERROR_MESSAGES
} from "../../constants";
import { ENV_CONFIG } from "../../config/env";
import { Button, Tooltip, Box } from "../../materialUi/components";
import { validatePageUrl } from "../../util/pagesHandlers";
import { validateExtraSpace } from "../../util";
import CatalogUpdatePageContent from "./updatePageContent";
import ConfirmModal from "../modal/ConfirmModal";
import SavePreviewModal from "../modal/SavePreviewModal";
import Footer from "../../component/footer";

import { ApplyDropDown } from "../../component/applyDropDown";
import {
  selectActivePage,
  selectCountryId,
  selectInitialPagesContent,
  selectIsActivePageHome,
  selectPagesRouteFolder,
  selectResetToggled,
  selectStoreId,
  selectStoreList,
  selectToggleRollbackSave
} from "../../selectors";
import {
  toggleReset,
  resetPagesContent,
  updatePage,
  addNewComponent
} from "../../actions/pagesPage.action";
import { toastMsg } from "../../actions/common.action";
import { resetCountryId } from "../../actions/selectedCountyId.action";

class Content extends Component {
  state = {
    confirmModal: false,
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false
  };

  componentDidUpdate() {
    const {
      toggleReset,
      updatePageContent,
      initialPagesContent,
      resetToggled
    } = this.props;
    let clonedPagesContent = cloneDeep(updatePageContent);
    let clonedInitialPagesContent = cloneDeep(initialPagesContent);

    if (updatePageContent.content) {
      clonedPagesContent.content = clonedPagesContent.content.map(section =>
        omit(section, ["openAccordion"])
      );

      clonedInitialPagesContent.content = clonedInitialPagesContent.content.map(
        section => omit(section, ["openAccordion"])
      );
    }

    const isPageContentEqual = isEqual(
      clonedPagesContent,
      clonedInitialPagesContent
    );
    if (!isPageContentEqual && !resetToggled) {
      toggleReset(true);
    } else if (isPageContentEqual && resetToggled) {
      toggleReset(false);
    }
  }

  handleUpdatePage = tempsave => {
    const { updatePage, updatePageContent } = this.props;
    return updatePage(updatePageContent, tempsave);
  };

  handleSubmit = (tempsave = false) => {
    const {
      updatePageContent,
      toastMsg,
      isHomePage,
      activePage,
      resetCountryId
    } = this.props;

    const notValidate = isHomePage || (activePage || {}).scheduledPage;
    if (updatePageContent?.title) {
      const isValid = validateExtraSpace(updatePageContent.title);
      if (!isValid) {
        return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Page Title`);
      }
    }
    if (
      updatePageContent?.isGamingURL &&
      !(updatePageContent.url || "").endsWith("/game/")
    ) {
      return toastMsg("error", `Gaming URL should end with "/game/"`);
    }
    if (
      !updatePageContent?.isGamingURL &&
      (updatePageContent.url || "").endsWith("/game/")
    ) {
      return toastMsg("error", `URL shouldn't end with "/game/"`);
    }
    if (!notValidate) {
      const result = validatePageUrl(updatePageContent.url);

      if (!result.success) {
        return toastMsg("error", result.error);
      }
    }

    this.handleUpdatePage(tempsave).then(() => {
      if (!tempsave) resetCountryId();
      this.setState({ confirmPreviewModal: tempsave });
    });
  };

  handleReset = () => {
    const { confirmModal } = this.state;
    this.setState({ confirmModal: !confirmModal });
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  hidePreviewModal = () => {
    this.setState(prevProps => ({
      confirmPreviewModal: !prevProps.confirmPreviewModal
    }));
  };

  hidePreviewSubmitModal = () =>
    this.setState(prevProps => ({
      confirmPreviewSubmitModal: !prevProps.confirmPreviewSubmitModal
    }));

  confirmModal = () => {
    const { confirmModal } = this.state;
    const { resetPagesContent } = this.props;

    resetPagesContent();

    this.setState({ confirmModal: !confirmModal });
  };

  confirmPreviewModal = dates => {
    const { confirmPreviewModal } = this.state;
    const { updatePageContent, storeList, storeId, countryId } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${
          selectedCountry
            ? selectedCountry.countrySHORT.toLowerCase()
            : defaultCountry.countrySHORT.toLowerCase()
        }${updatePageContent.url}?prevMode=true${
          dates.from && dates.to
            ? `&date_from=${dates.from}&date_to=${dates.to}`
            : ""
        }`,
        "_blank"
      );
    });
  };

  addDynamicBannerClick = () => {
    const { addNewComponent } = this.props;
    addNewComponent(CATALOG_COMPONENT_TYPES.DYNAMIC_BANNER);
  };

  render() {
    const { confirmModal, confirmPreviewModal } = this.state;
    const {
      currentTab,
      updatePageContent,
      resetToggled,
      initialFolder,
      activePage,
      selectedCountryId,
      rollbackSave,
      accessRights
    } = this.props;

    const renderContent = () => {
      switch (currentTab) {
        case PAGES_PANEL.PAGES_UPDATE_PAGE_DISPLAY:
          return (
            <CatalogUpdatePageContent
              updatePageContent={updatePageContent}
              accessRights={accessRights}
            />
          );
        default:
          return (
            <div>
              <h3 className="text-center">Select a page to get some content</h3>
            </div>
          );
      }
    };

    return (
      <div className="content-container-wrapper">
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={"Are you sure you want to cancel ?"}
          />
        )}
        {confirmPreviewModal && (
          <SavePreviewModal
            modalName={{}}
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={"Do you want to see the changes?"}
          />
        )}
        <div>{renderContent()}</div>
        <Footer>
          <Box sx={{ display: "flex" }}>
            <Tooltip
              title={accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE}
              placement="top"
              arrow
            >
              <span>
                <Button
                  onClick={this.addDynamicBannerClick}
                  disabled={!activePage || !accessRights.WRITE}
                  variant="contained"
                  sx={{ marginRight: "20px" }}
                >
                  Add Dynamic Module
                </Button>
              </span>
            </Tooltip>
            <Box className="footer" sx={{ width: "50%" }}>
              <Box className="footer-wrapper catalog">
                <ApplyDropDown
                  onlyCountries
                  style={{ justifyContent: "flex-start" }}
                />
              </Box>
            </Box>
            <Button
              onClick={this.handleReset}
              disabled={!resetToggled}
              sx={{ marginLeft: "auto" }}
            >
              Cancel
            </Button>
            <Tooltip
              title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
              placement="top"
              arrow
            >
              <span>
                <Button
                  onClick={() => this.handleSubmit(true)}
                  disabled={
                    (!resetToggled &&
                      selectedCountryId.length === 1 &&
                      !rollbackSave) ||
                    !accessRights.UPDATE
                  }
                  variant="contained"
                  sx={{ marginLeft: "20px" }}
                >
                  Save & Preview
                </Button>
              </span>
            </Tooltip>
            {initialFolder &&
              !initialFolder.scheduled &&
              !initialFolder.variant && (
                <Tooltip
                  title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
                  placement="top"
                  arrow
                >
                  <span>
                    <Button
                      onClick={() => this.handleSubmit(false)}
                      disabled={
                        updatePageContent.isPublished || !accessRights.UPDATE
                      }
                      variant="contained"
                      sx={{ marginLeft: "20px" }}
                    >
                      Publish
                    </Button>
                  </span>
                </Tooltip>
              )}
          </Box>
        </Footer>
      </div>
    );
  }
}

Content.propTypes = {};

const mapStateToProps = state => ({
  countryId: selectCountryId(state),
  storeId: selectStoreId(state),
  storeList: selectStoreList(state),
  initialPagesContent: selectInitialPagesContent(state),
  resetToggled: selectResetToggled(state),
  initialFolder: selectPagesRouteFolder(state),
  activePage: selectActivePage(state),
  selectedCountryId: state.selectedCountryId,
  isHomePage: selectIsActivePageHome(state),
  rollbackSave: selectToggleRollbackSave(state)
});

const mapDispatchToProps = {
  toggleReset,
  resetPagesContent,
  updatePage,
  addNewComponent,
  toastMsg,
  resetCountryId
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
