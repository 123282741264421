import qs from "query-string";

import {
  SET_CONFIGURATIONS_ACTIVE_PAGE,
  UPDATE_CONFIG_TYPE_CONFIGURATION_PAGE,
  ADD_CONFIG_TYPE_CUSTOM_FIELD,
  REMOVE_CONFIG_TYPE_CUSTOM_FIELD,
  UPDATE_CONFIG_DESC_CONFIGURATION_PAGE,
  SET_INITIAL_CONFIGURATIONS,
  TOGGLE_RESET_CONFIGURATIONS_PAGE,
  RESET_CONFIGURATIONS_CONTENT,
  RESET_CONFIGURATIONS_DATA,
  RESET_CONFIGURATIONS,
  SET_CONFIG_ROLLBACK_HISTORY,
  TOGGLE_CONFIG_ROLLBACK_SAVE,
  SAVE_CONFIG_DATA,
  SET_CONFIG_DATA,
  CLONE_TAB_CONFIGURATIONS,
  CLONE_DEVICE_CONFIGURATIONS,
  CLONE_PLATFORM_CONFIGURATIONS,
  RESET_ACTIVE_PAGE_CONFIGURATIONS,
  SWITCH_ROW_CONFIGURATION_PAGE,
  SET_DEFAULT_CONFIGURATIONS,
} from "../constants/actions";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  toastMsg,
  snackbar,
} from "./common.action";
import {
  deleteWebApi,
  fetchWebApi,
  postDashboardWebApi,
  putDashboardWebApi
} from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { ENV_CONFIG } from "../config/env";
import configurationsReducer from "../reducers/configurations.reducer";

export const setActivePage = page => ({
  type: SET_CONFIGURATIONS_ACTIVE_PAGE,
  payload: page
});

export const toggleReset = value => ({
  type: TOGGLE_RESET_CONFIGURATIONS_PAGE,
  value
});

export const resetDatas = value => ({
  type: RESET_CONFIGURATIONS_DATA,
  value
});

export const resetConfigurationsContent = () => ({
  type: RESET_CONFIGURATIONS_CONTENT
});
export const saveCurrentData = () => ({
  type: SAVE_CONFIG_DATA
});
export const resetConfigurations = () => ({
  type: RESET_CONFIGURATIONS
});
export const resetActivePage = () => ({
  type: RESET_ACTIVE_PAGE_CONFIGURATIONS
});

export const setInitialConfigurations = configurations => ({
  type: SET_INITIAL_CONFIGURATIONS,
  payload: configurations
});

export const updateConfigTypeCustomData = ({
  index,
  language,
  device,
  platform,
  name,
  value,
  data,
  id
}) => ({
  type: `${UPDATE_CONFIG_TYPE_CONFIGURATION_PAGE}`,
  index,
  language,
  device,
  platform,
  name,
  value,
  data,
  id
});

export const updateConfigTypeDesc = ({ index, path, name, value }) => ({
  type: `${UPDATE_CONFIG_DESC_CONFIGURATION_PAGE}`,
  path,
  index,
  name,
  value
});

export const addRowTypeCustomField = ({ configType, device, platform }) => ({
  type: `${ADD_CONFIG_TYPE_CUSTOM_FIELD}`,
  configType,
  device,
  platform
});

export const removeRowTypeCustomField = ({ index, device, platform, id }) => ({
  type: `${REMOVE_CONFIG_TYPE_CUSTOM_FIELD}`,
  index,
  device,
  platform,
  id
});

export const setPagesRollbackHistory = history => ({
  type: SET_CONFIG_ROLLBACK_HISTORY,
  payload: history
});

export const toggleRollbackSave = value => ({
  type: TOGGLE_CONFIG_ROLLBACK_SAVE,
  value
});

export const cloneTab = ({ path, tab, platform }) => ({
  type: `${CLONE_TAB_CONFIGURATIONS}`,
  path,
  tab,
  platform
});

export const cloneDevice = ({ from, to }) => ({
  type: `${CLONE_DEVICE_CONFIGURATIONS}`,
  from,
  to
});

export const clonePlatform = ({ device, from, to }) => ({
  type: `${CLONE_PLATFORM_CONFIGURATIONS}`,
  device,
  from,
  to
});

export const switchConfigRows = ({
  sourceIndex,
  destinationIndex,
  device,
  platform,
}) => ({
  type: `${SWITCH_ROW_CONFIGURATION_PAGE}`,
  sourceIndex,
  destinationIndex,
  device,
  platform,
});

export const fetchConfigurationsPages = countryId => {
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/configurations`,
        query: { countryId }
      })
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(setInitialConfigurations(response.data.data));
        }

        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);

        return error.response;
      });
  };
};

export const getPageData = pageId => (dispatch, getState) => {
  const state = getState();
  const { store, configurationsReducer } = state;
  const { countryId, storeId } = store;

  dispatch(showLoadingIndicator());

  return fetchWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/configurations/${pageId}`,
      query: { storeId, countryId }
    })
  )
    .then(response => {
      const {
        data: { code, data }
      } = response;

      if (code === 200) {
        dispatch({
          type: SET_CONFIG_DATA,
          payload: data
        });
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

export const getPageDataUsingSlug = slug => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;

  return fetchWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/configurations/slug/${slug}`,
      query: { countryId }
    })
  )
    .then(response => {
      const {
        data: { code, data }
      } = response;

      if (code === 200) {
        dispatch({
          type: SET_DEFAULT_CONFIGURATIONS,
          payload: data
        });
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    });
};

export const createConfiguration = (name, slug) => (dispatch, getState) => {
  dispatch(showLoadingIndicator());

  const state = getState();
  const { store } = state;
  const { countryId } = store;

  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/configurations`
    }),
    {
      name,
      slug,
      countryId
    }
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(snackbar({ open: true, severity: "success", text: "Created successfully" }));
          dispatch(setInitialConfigurations(response.data.data));
        } else {
          dispatch(snackbar({ open: true, severity: "error", text: response.data.error.message || "Something went wrong" }));
        }
      }

      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    });
};

export const updateConfigName = data => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  const state = getState();
  const { store } = state;
  const { countryId } = store;

  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/configurations/${data._id}`
    }),
    {
      name: data.name,
      slug: data.slug,
      countryId: countryId
    }
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(snackbar({ open: true, severity: "success", text: "Updated successfully" }));
          dispatch(setInitialConfigurations(response.data.data));
        } else {
          dispatch(snackbar({ open: true, severity: "error", text: response.data.error.message || "Something went wrong" }));
        }
      }

      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    });
};

export const deleteConfiguration = id => (dispatch, getState) => {
  dispatch(showLoadingIndicator());

  const state = getState();
  const { store } = state;
  const { countryId } = store;

  return deleteWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/configurations/${id}`,
      query: { countryId }
    })
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(snackbar({ open: true, severity: "success", text: "Deleted successfully" }));
          dispatch(setInitialConfigurations(response.data.data));
          dispatch(setActivePage(null));
        } else {
          dispatch(snackbar({ open: true, severity: "error", text: response.data.error.message || "Something went wrong" }));
        }
      }

      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    });
};

export const updateConfiguration = tempsave => (dispatch, getState) => {
  dispatch(showLoadingIndicator());

  const state = getState();
  const {
    store,
    selectedCountryId,
    configurationsReducer: { activePage }
  } = state;
  const { countryId } = store;

  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/configurations/${activePage._id}`,
      query: { countryId }
    }),
    { ...activePage, countryIds: selectedCountryId },
    tempsave
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(snackbar({ open: true, severity: "success", text: "Updated successfully" }));
          dispatch(setInitialConfigurations(response.data.data));
          dispatch(resetDatas(false));
          dispatch(saveCurrentData(activePage._id));
          dispatch(toggleRollbackSave(false));
        } else {
          dispatch(snackbar({ open: true, severity: "error", text: response.data.error.message || "Something went wrong" }));

        }
      }

      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    });
};

//Rollback
export const getAllRollbackHistory = (id, fromDate, toDate) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;

  dispatch(showLoadingIndicator());

  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/configurations/${id}?countryId=${countryId}`
    }),
    { fromDate, toDate }
  )
    .then(response => {
      dispatch(setPagesRollbackHistory(response.data));
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

const manageRequestPromiseRollback = (requestPromise, dispatch, activityType) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(setActivePage(data.data));
        dispatch(snackbar({ open: true, severity: "success", text: "Rollback successful" }));
        dispatch(toggleRollbackSave(true));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            ERROR_MESSAGES[activityType]
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const setPageRollback = id => (dispatch, getState) => {
  const state = getState();
  dispatch(showLoadingIndicator());
  return manageRequestPromiseRollback(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/history/configurations/rollback`
      }),
      { versionId: id }
    ),
    dispatch
  );
};

export const getRollbackCompareVersionData = (id, versions) => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/configurations/content?referenceId=${id}`
    }),
    { versions }
  )
    .then(response => {
      if (!response || response.status !== 200 ||
        !response.data || !response.data.data ||
        response.data.data.length < 2) {
        dispatch(snackbar({ open: true, severity: "error", text: "Error while fetching versions Info " }));
        return []
      } else {
        return response.data.data
      }
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

const manageRequestPromiseCopyToOtherCountries = (
  requestPromise,
  dispatch,
  activityType
) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(snackbar({ open: true, severity: "success", text: "Copied successfully" }));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            ERROR_MESSAGES[activityType]
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const copyToOtherCountries = (
  deviceTab,
  platformTab,
  content,
  data,
  destinationCountry
) => (dispatch, getState) => {
  const resetData = getState().configurationsReducer.resetData;
  const bodyData = {
    device: deviceTab,
    platform: platformTab,
    destinationCountryId: destinationCountry,
    content: content,
    configurationData: {
      name: data.name,
      slug: data.slug,
      countryId: data.countryId
    },
    isOverWrite: false
  };
  if (!resetData) {
    dispatch(showLoadingIndicator());
    return manageRequestPromiseCopyToOtherCountries(
      postDashboardWebApi(
        getAccessToken(getState),
        qs.stringifyUrl({
          url: `${ENV_CONFIG.configBaseURL}/configurations/clone-entry`
        }),
        bodyData
      ),
      dispatch
    );
  } else {
    dispatch(snackbar({ open: true, severity: "error", text: "Please save all the changes before you clone" }));
  }
};
