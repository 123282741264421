import { useMemo } from "react";
import * as yup from "yup";
import isEqual from "lodash/isEqual";
import {
  bannerTypeValueConstants,
  HOMEPAGE_SECTION_TYPES,
  ERROR_MESSAGES
} from "../../constants";

const generalSeoSchema = yup.object({
  english: yup
    .object({
      title: yup.string().required(),
      metaKeyword: yup.string().required(),
      metaDescription: yup.string().required(),
      headingH1: yup.string().required(),
      headingH2: yup.string().required()
    })
    .required(),
  arabic: yup
    .object({
      title: yup.string().required(),
      metaKeyword: yup.string().required(),
      metaDescription: yup.string().required(),
      headingH1: yup.string().required(),
      headingH2: yup.string().required()
    })
    .required(),
  id: yup.string().required(),
  type: yup.string().required()
});

const bannerSchedulerSchema = yup
  .array()
  .of(
    yup
      .mixed()
      .test(
        "banner schedulers validation",
        "banner schedulers validation error",
        scheduler => {
          if (scheduler.enabled) {
            return yup
              .object({
                to: yup.number().required(),
                from: yup.number().required(),
                enabled: yup.boolean().required(),
                template: yup
                  .object({
                    english: yup.string().required(),
                    arabic: yup.string().required()
                  })
                  .required()
              })
              .isValidSync(scheduler);
          }
          return true;
        }
      )
      .required()
  )
  .required();

const bannerSchema = yup.object({
  defaultTemplate: yup
    .object({
      english: yup.string().required(),
      arabic: yup.string().required()
    })
    .required(),
  schedulers: bannerSchedulerSchema,
  id: yup.string().required(),
  type: yup.string().required()
});

const newestArrivalSchema = yup.object({
  english: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required()
    })
    .required(),
  arabic: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required()
    })
    .required(),
  arrivalList: yup.array().of(
    yup
      .object({
        english: yup
          .object({
            categoryName: yup.string().required(),
            subCategoryName: yup.string().required(),
            redirectionLink: yup.string().required(),
            imageLink: yup.string().required()
          })
          .required(),
        arabic: yup
          .object({
            categoryName: yup.string().required(),
            subCategoryName: yup.string().required(),
            redirectionLink: yup.string().required(),
            imageLink: yup.string().required()
          })
          .required()
      })
      .required()
  ),
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required()
});

const shopByBrandsSchema = yup.object({
  english: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required(),
      ctaDisplay: yup.string().required(),
      ctaRedirection: yup.string().required()
    })
    .required(),
  arabic: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required(),
      ctaDisplay: yup.string().required(),
      ctaRedirection: yup.string().required()
    })
    .required(),
  brandLogos: yup.array().of(
    yup
      .object({
        english: yup
          .object({
            brandName: yup.string().required(),
            description: yup.string().required(),
            redirectionLink: yup.string().required(),
            imageLink: yup.string().required()
          })
          .required(),
        arabic: yup
          .object({
            brandName: yup.string().required(),
            description: yup.string().required(),
            redirectionLink: yup.string().required(),
            imageLink: yup.string().required()
          })
          .required()
      })
      .required()
  ),
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required()
});

const topCategoriesSchema = yup.object({
  english: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required()
    })
    .required(),
  arabic: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required()
    })
    .required(),
  categories: yup.array().of(
    yup
      .object({
        english: yup
          .object({
            categoryName: yup.string().required(),
            redirectionLink: yup.string().required(),
            imageLink: yup.string().required()
          })
          .required(),
        arabic: yup
          .object({
            categoryName: yup.string().required(),
            redirectionLink: yup.string().required(),
            imageLink: yup.string().required()
          })
          .required()
      })
      .required()
  ),
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required()
});

const storeListSchema = yup.array().of(
  yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required(),
      storeId: yup.number().required(),
      imageLink: yup.string().required(),
      hoverImageLink: yup.string().required(),
      responsiveImageLink: yup.string().required(),
      iconImageLink: yup.string().required(),
      storeLogoImageLink: yup.string().required()
    })
    .required()
);

const shopByStoreSchema = yup.object({
  english: yup
    .object({
      mainTitle: yup.string().required(),
      storeList: storeListSchema
    })
    .required(),
  arabic: yup
    .object({
      mainTitle: yup.string().required(),
      storeList: storeListSchema
    })
    .required(),
  enabled: yup.array().of(yup.number()).required(),
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required()
});

const imageListSchema = yup.array().of(
  yup
    .object({
      english: yup
        .object({
          title: yup.string().required(),
          subTitle: yup.string().required(),
          GtmTag: yup.string().required(),
          redirectionLink: yup.string().required(),
          imageLink: yup.string().required()
        })
        .required(),
      arabic: yup
        .object({
          title: yup.string().required(),
          subTitle: yup.string().required(),
          GtmTag: yup.string().required(),
          redirectionLink: yup.string().required(),
          imageLink: yup.string().required()
        })
        .required(),
      enable: yup.boolean()
    })
    .required()
);

const sliderImageListSchema = yup.array().of(
  yup
    .object({
      english: yup
        .object({
          desktopImageList: yup.string().required(),
          mobileImageList: yup.string().required(),
          redirectionLink: yup.string().required()
        })
        .required(),
      arabic: yup
        .object({
          desktopImageList: yup.string().required(),
          mobileImageList: yup.string().required(),
          redirectionLink: yup.string().required()
        })
        .required(),
      enable: yup.boolean()
    })
    .required()
);

const imageCarouselSchedulersSchema = yup
  .array()
  .of(
    yup
      .mixed()
      .test(
        "image carousel schedulers validation",
        "image carousel schedulers validation error",
        scheduler => {
          if (scheduler.enabled) {
            return yup
              .object({
                to: yup.number().required(),
                from: yup.number().required(),
                enabled: yup.boolean().required(),
                english: yup
                  .object({
                    title: yup.string().required(),
                    subTitle: yup.string().required()
                  })
                  .required(),
                arabic: yup
                  .object({
                    title: yup.string().required(),
                    subTitle: yup.string().required()
                  })
                  .required(),
                imageList: imageListSchema
              })
              .isValidSync(scheduler);
          }
          return true;
        }
      )
      .required()
  )
  .required();

const imageCarouselSchema = yup.object({
  english: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required()
    })
    .required(),
  arabic: yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required()
    })
    .required(),
  imageList: imageListSchema,
  schedulers: imageCarouselSchedulersSchema,
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required()
});

const productCarouselSchedulersSchema = yup
  .array()
  .of(
    yup
      .mixed()
      .test(
        "product carousel schedulers validation",
        "product carousel schedulers validation error",
        scheduler => {
          if (scheduler.enabled) {
            return yup
              .object({
                to: yup.number().required(),
                from: yup.number().required(),
                enabled: yup.boolean().required(),
                english: yup
                  .object({
                    title: yup.string().required(),
                    searchUrl: yup.string().required()
                  })
                  .required(),
                arabic: yup
                  .object({
                    title: yup.string().required(),
                    searchUrl: yup.string().required()
                  })
                  .required()
              })
              .isValidSync(scheduler);
          }
          return true;
        }
      )
      .required()
  )
  .required();

const productCarouselSchema = yup.object({
  english: yup
    .object({
      title: yup.string().required(),
      searchUrl: yup.string().required()
    })
    .required(),
  arabic: yup
    .object({
      title: yup.string().required(),
      searchUrl: yup.string().required()
    })
    .required(),
  schedulers: productCarouselSchedulersSchema,
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required()
});

const sliderSchema = yup.object({
  imageList: sliderImageListSchema,
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required()
});

const dynamicSliderImageListSchema = yup
  .array()
  .of(
    yup
      .object({
        english: yup
          .object({
            desktop: yup.string().required(),
            mobile: yup.string().required(),
            tablet: yup.string().required(),
            enabledOn: yup.array().of(yup.string()).required(),
            redirectionLink: yup
              .string()
              .required()
              .typeError("Redirection link should be not empty")
          })
          .required(),
        arabic: yup
          .object({
            desktop: yup.string().required(),
            mobile: yup.string().required(),
            tablet: yup.string().required(),
            enabledOn: yup.array().of(yup.string()).required(),
            redirectionLink: yup
              .string()
              .required()
              .typeError("Redirection link should be not empty")
          })
          .required()
      })
      .required()
  )
  .min(1)
  .typeError("Should contain at least one element");

export const dynamicSliderSchema = yup.object({
  sliders: dynamicSliderImageListSchema.required(),
  enable: yup.boolean(),
  id: yup.string().required(),
  type: yup.string().required(),
  bulletType: yup.string().notRequired(),
  bulletColor: yup.string().notRequired()
});

const dynamicBannerSpacingStyles = yup.object({
  paddingTop: yup.number(),
  paddingRight: yup.number(),
  paddingBottom: yup.number(),
  paddingLeft: yup.number(),
  marginTop: yup.number(),
  marginRight: yup.number(),
  marginBottom: yup.number(),
  marginLeft: yup.number()
});

const dynamicBannerStylingImageSchema = yup.object().shape({
  paddingTop: yup.number(),
  paddingRight: yup.number(),
  paddingBottom: yup.number(),
  paddingLeft: yup.number(),
  marginTop: yup.number(),
  marginRight: yup.number(),
  marginBottom: yup.number(),
  marginLeft: yup.number(),
  hover: yup.object().shape({
    opacity: yup.number(),
    transform: yup.string(),
    transition: yup.string()
  }),
  hoverValues: yup.object().shape({
    opacity: yup.number(),
    transform: yup.number(),
    transition: yup.number()
  })
});

const dynamicBannerRowStylingSchema = yup.object().shape({
  paddingTop: yup.number(),
  paddingRight: yup.number(),
  paddingBottom: yup.number(),
  paddingLeft: yup.number(),
  marginTop: yup.number(),
  marginBottom: yup.number(),
  containerWidth: yup.string()
});

const dynamicBannerIndicatorStyles = yup.object().shape({
  indicatorColor: yup.string(),
  backgroundIndicatorColor: yup.string()
});

//Note: May be used Later
// export const bannerImageListSchema = isDesktop => {
//   return yup
//     .array()
//     .ensure()
//     .when("bannerType", {
//       is: (val) => val === bannerTypeValueConstants.PRODUCTCAROUSEL || val === bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL || val === bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL,
//       then: yup.array().of(bannerImageSchema(isDesktop)),
//       otherwise: yup.array().of(bannerImageSchema(isDesktop)).min(1).required()
//     });
// };

const productCarouselShape = yup.object().shape({
  title: yup.string(),
  searchUrl: yup.string()
});

//Note: May be used Later
// export const bannerDeviceConfiguration = isDesktop => {
//   const width = isDesktop ? yup.string().required() : yup.string();
//   return yup
//     .object({
//       rows: yup
//         .array()
//         .of(
//           yup.object({
//             id: yup.string().required(),
//             bannerType: yup.string().required(),
//             width,
//             customWidth: yup.number(),
//             enable: yup.boolean().required(),
//             imageList: yup
//               .array()
//               .ensure()
//               .when("bannerType", {
//                 is: (val) => val === bannerTypeValueConstants.PRODUCTCAROUSEL || val === bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL || val === bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL || val === bannerTypeValueConstants.CUSTOM,
//                 then: yup.array().of(bannerImageSchema(isDesktop)),
//                 otherwise: yup
//                   .array()
//                   .of(bannerImageSchema(isDesktop))
//                   .min(1)
//                   .required()
//               }),
//             english: productCarouselShape,
//             arabic: productCarouselShape,
//             schedulers: yup.array().of(
//               yup
//                 .object({
//                   bannerType: yup.string().ensure().when("enabled", {
//                     is: true,
//                     then: yup.string().required()
//                   }),
//                   enabled: yup.boolean(),
//                   imageList: yup.array(),
//                   english: productCarouselShape,
//                   arabic: productCarouselShape,
//                   from: yup.string().ensure().when("enabled", {
//                     is: true,
//                     then: yup.string().required()
//                   }),
//                   to: yup.string().ensure().when("enabled", {
//                     is: true,
//                     then: yup.string().required()
//                   }),
//                   width: yup.string().ensure().when("enabled", {
//                     is: true,
//                     then: yup.string().required()
//                   }),
//                   customWidth: yup.number(),
//                   styles: dynamicBannerRowStylingSchema,
//                   indicatorStyles: dynamicBannerIndicatorStyles,
//                   indicatorType: yup.string(),
//                   autoPlay: yup.string(),
//                   bulletType: yup.string(),
//                   bulletColor: yup.string()
//                 })
//                 .required()
//             ),
//             styles: dynamicBannerRowStylingSchema,
//             indicatorStyles: dynamicBannerIndicatorStyles,
//             indicatorType: yup.string(),
//             autoPlay: yup.string(),
//             bulletType: yup.string(),
//             bulletColor: yup.string()
//           })
//         )
//         .min(1)
//         .typeError("Should contain at least one element"),
//       styles: yup.object().shape(
//         {
//           backgroundType: yup.string().required(),
//           backgroundImage: yup.string().ensure().when("backgroundColor", {
//             is: "",
//             then: yup.string().required()
//           }),
//           backgroundColor: yup.string().ensure().when("backgroundImage", {
//             is: "",
//             then: yup.string().required()
//           }),
//           paddingTop: yup.number(),
//           paddingRight: yup.number(),
//           paddingBottom: yup.number(),
//           paddingLeft: yup.number(),
//           marginTop: yup.number(),
//           marginBottom: yup.number(),
//           gradientType: yup.string().ensure().when("backgroundType", {
//             is: "backgroundGradient",
//             then: yup.string().required()
//           }),
//           primaryBackgroundColor: yup.string(),
//           secondaryBackgroundColor: yup.string(),
//           primaryLocation: yup.number(),
//           secondaryLocation: yup.number(),
//           gradientAngle: yup.number()
//         },
//         [["backgroundColor", "backgroundImage"]]
//       )
//     })
//     .required();
// };

export const dynamicBannerSchema = yup.object({
  enable: yup.boolean(),
  type: yup.string().required(),
  id: yup.string().required()
});

export const bannerImageSchema = (isDesktop, isArabicRequired) => {
  const enabledOn = isDesktop
    ? yup.array().of(yup.string())
    : yup.array().of(yup.string()).required().min(1);
  let arabicObject;
  if (isArabicRequired) {
    arabicObject = yup
      .object({
        imageURL: yup.string().required(),
        enabledOn,
        redirectionLink: yup
          .string()
          .test(
            "redirectionlink-validator",
            ERROR_MESSAGES.invalidRedirectionlink,
            function (value = "") {
              const { path, createError } = this;
              if (!value.length || value.startsWith("http")) {
                return true;
              }
              if (!value.startsWith("/")) {
                return createError({
                  path,
                  message: ERROR_MESSAGES.redirectionlinkStartsWith
                });
              }
              if (value.indexOf("?") === -1 && !value.endsWith("/")) {
                return createError({
                  path,
                  message: ERROR_MESSAGES.redirectionlinkEndsWith
                });
              }
              if (
                value.indexOf("?") !== -1 &&
                value[value.indexOf("?") - 1] !== "/"
              ) {
                return createError({
                  path,
                  message: ERROR_MESSAGES.redirectionlinkWithParams
                });
              }
              return true;
            }
          )
      })
      .required();
  } else {
    arabicObject = yup.object({
      imageURL: yup.string(),
      enabledOn,
      redirectionLink: yup
        .string()
        .test(
          "redirectionlink-validator",
          ERROR_MESSAGES.invalidRedirectionlink,
          function (value = "") {
            const { path, createError } = this;
            if (!value.length || value.startsWith("http")) {
              return true;
            }
            if (!value.startsWith("/")) {
              return createError({
                path,
                message: ERROR_MESSAGES.redirectionlinkStartsWith
              });
            }
            if (value.indexOf("?") === -1 && !value.endsWith("/")) {
              return createError({
                path,
                message: ERROR_MESSAGES.redirectionlinkEndsWith
              });
            }
            if (
              value.indexOf("?") !== -1 &&
              value[value.indexOf("?") - 1] !== "/"
            ) {
              return createError({
                path,
                message: ERROR_MESSAGES.redirectionlinkWithParams
              });
            }
            return true;
          }
        )
    });
  }
  return yup
    .object({
      english: yup
        .object({
          imageURL: yup.string().required(),
          enabledOn,
          redirectionLink: yup
            .string()
            .test(
              "redirectionlink-validator",
              ERROR_MESSAGES.invalidRedirectionlink,
              function (value = "") {
                const { path, createError } = this;
                if (!value.length || value.startsWith("http")) {
                  return true;
                }
                if (!value.startsWith("/")) {
                  return createError({
                    path,
                    message: ERROR_MESSAGES.redirectionlinkStartsWith
                  });
                }
                if (value.indexOf("?") === -1 && !value.endsWith("/")) {
                  return createError({
                    path,
                    message: ERROR_MESSAGES.redirectionlinkEndsWith
                  });
                }
                if (
                  value.indexOf("?") !== -1 &&
                  value[value.indexOf("?") - 1] !== "/"
                ) {
                  return createError({
                    path,
                    message: ERROR_MESSAGES.redirectionlinkWithParams
                  });
                }
                return true;
              }
            )
        })
        .required(),
      arabic: arabicObject,
      styles: dynamicBannerStylingImageSchema,
      type: yup.string(),
      bannerId: yup.string().required(),
      bannerTitle: yup.string().required()
    })
    .required();
};

export const bannerCountdownSchema = yup.object({
  date: yup.string().required(),
  styleType: yup.number().required(),
  styles: dynamicBannerSpacingStyles
});

export const bannerVideoSchema = (isDesktop, isArabicRequired) => {
  const enabledOn = isDesktop
    ? yup.array().of(yup.string())
    : yup.array().of(yup.string()).required().min(1);
  let arabicObject;
  if (isArabicRequired) {
    arabicObject = yup
      .object({
        videoUrl: yup.string().required(),
        enabledOn,
        redirectionLink: yup
          .string()
          .test(
            "redirectionlink-validator",
            ERROR_MESSAGES.invalidRedirectionlink,
            function (value = "") {
              const { path, createError } = this;
              if (!value.length || value.startsWith("http")) {
                return true;
              }
              if (!value.startsWith("/")) {
                return createError({
                  path,
                  message: ERROR_MESSAGES.redirectionlinkStartsWith
                });
              }
              if (value.indexOf("?") === -1 && !value.endsWith("/")) {
                return createError({
                  path,
                  message: ERROR_MESSAGES.redirectionlinkEndsWith
                });
              }
              if (
                value.indexOf("?") !== -1 &&
                value[value.indexOf("?") - 1] !== "/"
              ) {
                return createError({
                  path,
                  message: ERROR_MESSAGES.redirectionlinkWithParams
                });
              }
              return true;
            }
          )
      })
      .required();
  } else {
    arabicObject = yup.object({
      videoUrl: yup.string(),
      enabledOn,
      redirectionLink: yup
        .string()
        .test(
          "redirectionlink-validator",
          ERROR_MESSAGES.invalidRedirectionlink,
          function (value = "") {
            const { path, createError } = this;
            if (!value.length || value.startsWith("http")) {
              return true;
            }
            if (!value.startsWith("/")) {
              return createError({
                path,
                message: ERROR_MESSAGES.redirectionlinkStartsWith
              });
            }
            if (value.indexOf("?") === -1 && !value.endsWith("/")) {
              return createError({
                path,
                message: ERROR_MESSAGES.redirectionlinkEndsWith
              });
            }
            if (
              value.indexOf("?") !== -1 &&
              value[value.indexOf("?") - 1] !== "/"
            ) {
              return createError({
                path,
                message: ERROR_MESSAGES.redirectionlinkWithParams
              });
            }
            return true;
          }
        )
    });
  }
  return yup
    .object({
      english: yup
        .object({
          videoUrl: yup.string().required(),
          enabledOn,
          redirectionLink: yup
            .string()
            .test(
              "redirectionlink-validator",
              ERROR_MESSAGES.invalidRedirectionlink,
              function (value = "") {
                const { path, createError } = this;
                if (!value.length || value.startsWith("http")) {
                  return true;
                }
                if (!value.startsWith("/")) {
                  return createError({
                    path,
                    message: ERROR_MESSAGES.redirectionlinkStartsWith
                  });
                }
                if (value.indexOf("?") === -1 && !value.endsWith("/")) {
                  return createError({
                    path,
                    message: ERROR_MESSAGES.redirectionlinkEndsWith
                  });
                }
                if (
                  value.indexOf("?") !== -1 &&
                  value[value.indexOf("?") - 1] !== "/"
                ) {
                  return createError({
                    path,
                    message: ERROR_MESSAGES.redirectionlinkWithParams
                  });
                }
                return true;
              }
            )
        })
        .required(),
      arabic: arabicObject,
      styles: dynamicBannerSpacingStyles,
      bannerId: yup.string().required(),
      bannerTitle: yup.string().required()
    })
    .required();
};

const sectionsSchema = yup.array().of(
  yup
    .mixed()
    .test("sections validation", "sections validation error", section => {
      switch (section.type) {
        case HOMEPAGE_SECTION_TYPES.META:
          return generalSeoSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.TOP_BANNER_CONTENT:
          return bannerSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.NEWEST_ARRIVAL:
          return newestArrivalSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.USP_CONTENT:
          return bannerSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.SHOP_BY_BRANDS:
          return shopByBrandsSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.TOP_CATEGORIES:
          return topCategoriesSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.SHOP_BY_STORE:
          return shopByStoreSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.BANNER:
          return bannerSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.IMAGE_CAROUSEL:
          return imageCarouselSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL:
          return productCarouselSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.SLIDER:
          return sliderSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.DYNAMIC_SLIDER:
          return dynamicSliderSchema.isValidSync(section);
        case HOMEPAGE_SECTION_TYPES.DYNAMIC_BANNER:
          return dynamicBannerSchema.isValidSync(section);
        default:
          return true;
      }
    })
);

export const useDetermineIsSubmitDisabled = (
  homepageSections,
  updatedHomepageSections,
  isMultiStoreCountry
) =>
  useMemo(() => {
    let isDisabled = false;
    const isValid = sectionsSchema.isValidSync(updatedHomepageSections);

    if (isEqual(homepageSections, updatedHomepageSections)) {
      isDisabled = true;
    }
    if (isMultiStoreCountry) {
      isDisabled = false;
    }
    if (!isValid) {
      isDisabled = true;
    }
    return isDisabled;
  }, [homepageSections, updatedHomepageSections, isMultiStoreCountry]);

export const useDetermineIsResetDisabled = (
  homepageSections,
  updatedHomepageSections
) =>
  useMemo(() => {
    let isResetDisabled = false;
    if (isEqual(homepageSections, updatedHomepageSections)) {
      isResetDisabled = true;
    }
    return isResetDisabled;
  }, [homepageSections, updatedHomepageSections]);

export const useDetermineIsUnsavedChanges = (
  homepageSections,
  updatedHomepageSections
) =>
  useMemo(() => {
    return !isEqual(homepageSections, updatedHomepageSections);
  }, [homepageSections, updatedHomepageSections]);
